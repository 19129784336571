import {
  LOADING_GET_CLINICAL_RECORDS,
  LOADING_GET_CLINICAL_RECORD,
  SET_CLINICAL_RECORDS,
  SET_CLINICAL_RECORD,
  GET_CLINICAL_RECORDS_ERROR,
  GET_CLINICAL_RECORD_ERROR,
  ADD_CLINICAL_RECORD,
  LOADING_ADD_CLINICAL_RECORD,
  ADD_CLINICAL_RECORD_ERROR,
  UPDATE_CLINICAL_RECORD,
  UPDATE_CLINICAL_RECORD_ERROR,
  LOADING_UPDATE_CLINICAL_RECORD,
  DELETE_CLINICAL_RECORD,
  LOADING_DELETE_CLINICAL_RECORD,
  DELETE_CLINICAL_RECORD_ERROR,
  UPDATE_HISTORY_AND_SYMPTOMS,
  UPDATE_SUPPLEMENTARY,
  UPDATE_OCULAR_HEALTH,
  UPDATE_SUBJECTIVE,
  UPDATE_COMMENT_ADVICE,
  UPDATE_DRAWING_SECTION,
  UPDATE_IMAGE_SECTION,
  UPDATE_CONTACT_LENSES,
  SET_PATIENT_CLINICAL_STATUS,
  SELECT_CLINICAL_RECORD,
  UPDATE_MUSCLE_BALANCE_UPDATE_IMAGE_SECTION,
  UPDATE_MUSCLE_BALANCE_DRAWING_SECTION,
  UPDATE_PRE_SCREEN,
  UPDATE_ADDITIONAL_TEST,
  UPDATE_RECOMMENDATIONS, UPDATE_REFERRAL,
} from "../types";

const initialState = {
  clinical_records: [],
  clinical_record: {},
  addClinicalRecord: false,
  selectedRecord: null,
  referral: {
    patient_details: "",
    gp_details: "",
    referral_urgency: "",
    referral_reasons: "",
    iop_re: "",
    iop_le: "",
    disc_appearance_re: "",
    disc_appearance_le: "",
    field_results_le: "",
    field_results_re: "",
    notes: ""
  },
  historyAndSymptoms: {
    reason: "",
    visions: "",
    rx: "",
    lee: "",
    lme: "",
    vdu: "",
    hours: "",
    smoker: "",
    health: "",
    medication: "",
    family_ocular: "",
    occupation: "",
    hobbies: "",
    ocular: "",
    dm: false,
    glaucoma: false,
    amb: false,
    family_history: "",
    h_or_a: false,
    dip: false,
    flashes: false,
    floaters: false,
    cl: false,
    driver: false,
    notes: "",
    eyestrain: false
  },
  preScreen: {
    notes: "",
    time: "",
    staff_name: "",
    r_k_readings: "",
    l_k_readings: "",
    nonContact: false,
    goldmann: false,
    iCare: false,
    r_iop1: "",
    r_iop2: "",
    r_iop3: "",
    r_iopAv: "",
    l_iop1: "",
    l_iop2: "",
    l_iop3: "",
    l_iopAv: "",
    focimeter: [{
      svd: false,
      svn: false,
      svi: false,
      vari: false,
      bif: false,
      focimeter_r_sph: "",
      focimeter_r_cyl: "",
      focimeter_r_axis: "",
      focimeter_r_add: "",
      focimeter_l_sph: "",
      focimeter_l_cyl: "",
      focimeter_l_axis: "",
      focimeter_l_add: "",
    }],
    svd: false,
    svn: false,
    svi: false,
    vari: false,
    bif: false,
    focimeter_r_sph: "",
    focimeter_r_cyl: "",
    focimeter_r_axis: "",
    focimeter_r_add: "",
    focimeter_l_sph: "",
    focimeter_l_cyl: "",
    focimeter_l_axis: "",
    focimeter_l_add: "",
    addAnotherPair: "",
    refractor_r_sph: "",
    refractor_r_cyl: "",
    refractor_r_axis: "",
    refractor_l_sph: "",
    refractor_l_cyl: "",
    refractor_l_axis: "",
    drugName: "",
    iopPre: "",
    preTime: "",
    batchNo: "",
    expiryDate: "",
    timeInstallation: "",
    iopPost: "",
    postTime: ""
  },
  supplementary: {
    dvWith: "",
    dvWithMovement: "",
    dvWithout: "",
    dvWithoutMovement: "",
    nvWith: "",
    nvWithMovement: "",
    nvWithout: "",
    nvWithoutMovement: "",
    dvHorizontal: "",
    dvVertical: "",
    nvVertical: "",
    nvHorizontal: "",
    accomodation: "",
    motility: "",
    pupils: "",
    npc: "",
    notes: "",
  },
  additionalTest: {
    ishihara_normal: false,
    ishihara: "",
    amsler_normal: false,
    amsler: "",
    stereopsis_normal: false,
    stereopsis: "",
    not_required: false,
    completed: false,
    full_threshold: false,
    supra_threshold: false,
    confrontation: false,
    r_visual_fields: "",
    l_visual_fields: "",
    notes: "",
  },
  recommendations: {
    adviceGiven: "",
    lensRecommendation: "",
    lensUpgrade: "",
    advice: "",
    recall: "",
    testType1: "",
    testType2: "",
  },
  ocularHealth: {
    r_anterior_drawing: {
      paths: [],
      image: null
    },
    l_anterior_drawing: {
      paths: [],
      image: null
    },
    r_posterior_drawing: {
      paths: [],
      image: null
    },
    l_posterior_drawing: {
      paths: [],
      image: null
    },
    r_extarnal: "",
    r_lids_lashes: "",
    r_conjunctiva: "",
    r_cornea: "",
    r_anterior_chamber: "",
    r_lens: "",
    r_vitreous: "",
    r_discs: "",
    r_c_d: "",
    r_vessels: "",
    r_av: "",
    r_macula: "",
    r_fundus: "",
    r_notes: "",
    l_extarnal: "",
    l_lids_lashes: "",
    l_conjunctiva: "",
    l_cornea: "",
    l_anteriol_chamber: "",
    l_lens: "",
    l_vitreous: "",
    l_discs: "",
    l_c_d: "",
    l_vessels: "",
    l_av: "",
    l_macula: "",
    l_fundus: "",
    l_notes: "",
    predialtion_time: "",
    predialtion_tonometry_r: "",
    predialtion_tonometry_l: "",
    predialtion_tonometry_instrument: "",
    postdialtion_time: "",
    postdialtion_tonometry_r: "",
    postdialtion_tonometry_l: "",
    postdialtion_tonometry_instrument: "",
    ocular_health_note: "",
    drawing_notes: "",
  },
  subjective: {
    r_current_va: "",
    l_current_va: "",
    sx_r_vision: "",
    sx_r_bv: "",
    sx_r_sph: "+0.00",
    sx_r_cyl: "+0.00",
    sx_r_axis: "0",
    sx_r_dva: "",
    sx_r_dvb: "",
    sx_r_add: "",
    sx_r_nva: "",
    sx_r_iv_add: "",
    sx_r_pd: "",
    sx_r_bvd: "",
    sx_l_vision: "",
    sx_l_bv: "",
    sx_l_sph: "+0.00",
    sx_l_cyl: "+0.00",
    sx_l_axis: "0",
    sx_l_dva: "",
    sx_l_dvb: "",
    sx_l_add: "",
    sx_l_nva: "",
    sx_l_iv_add: "",
    sx_l_pd: "",
    sx_l_bvd: "",
    px_r_vision: "",
    px_r_bv: "",
    px_r_sph: "+0.00",
    px_r_cyl: "+0.00",
    px_r_axis: "0",
    px_r_dva: "",
    px_r_dvb: "",
    px_r_add: "",
    px_r_nva: "",
    px_r_iv_add: "",
    px_r_pd: "",
    px_r_bvd: "",
    px_l_vision: "",
    px_l_bv: "",
    px_l_sph: "+0.00",
    px_l_cyl: "+0.00",
    px_l_axis: "0",
    px_l_dva: "",
    px_l_dvb: "",
    px_l_add: "",
    px_l_nva: "",
    px_l_iv_add: "",
    px_l_pd: "",
    px_l_bvd: "",
    sx_dp_r_h: "",
    sx_dp_r_v: "",
    sx_dp_l_h: "",
    sx_dp_l_v: "",
    sx_np_r_h: "",
    sx_np_r_v: "",
    sx_np_l_h: "",
    sx_np_l_v: "",
    px_dp_r_h: "",
    px_dp_r_v: "",
    px_dp_l_h: "",
    px_dp_l_v: "",
    px_np_r_h: "",
    px_np_r_v: "",
    px_np_l_h: "",
    px_np_l_v: "",
  },
  commentAdvice: {
    date: new Date(),
    final_comment: "",
    final_advice: "",
    recallcategoryid: null,
    type: null,
    comments: "",
    image: "",
  },
  drawingSection: {
    paths: [],
  },
  imageSection: {
    image: null,
  },
  muscleBalanceDrawingSection: {
    paths: [],
  },
  muscleBalanceImageSection: {
    image: null,
  },
  contactLenses: {
    r_sph: "+0.00",
    r_cyl: "+0.00",
    r_axis: "0",
    r_add: "",
    r_cl_fit: "",
    l_sph: "+0.00",
    l_cyl: "+0.00",
    l_axis: "0",
    l_add: "",
    l_cl_fit: "",
    prescription_type: "",
    recommendation: "",
  },
  loading_clinical_record: false,
  loading_clinical_records: false,
  clinical_records_errors: null,
  clinical_record_errors: null,
  loading_add_clinical_record: false,
  add_clinical_record_errors: null,
  loading_update_clinical_record: false,
  update_clinical_record_errors: null,
  loading_delete_clinical_record: false,
  delete_clinical_record_errors: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_GET_CLINICAL_RECORDS:
      return {
        ...state,
        loading_clinical_records: true,
        clinical_records_errors: null,
      };
    case LOADING_GET_CLINICAL_RECORD:
      return {
        ...state,
        loading_clinical_record: true,
        clinical_records_error: null,
      };
    case LOADING_UPDATE_CLINICAL_RECORD:
      return {
        ...state,
        loading_update_clinical_record: true,
        update_clinical_record_errors: null,
      };
    case LOADING_DELETE_CLINICAL_RECORD:
      return {
        ...state,
        loading_delete_clinical_record: true,
        delete_clinical_record_errors: null,
      };
    case LOADING_ADD_CLINICAL_RECORD:
      return {
        ...state,
        loading_add_clinical_record: true,
        add_clinical_record_errors: null,
      };
    case SET_CLINICAL_RECORDS:
      return {
        ...state,
        clinical_records: action.payload.data,
        loading_clinical_records: false,
        clinical_records_errors: null,
      };
    case SET_CLINICAL_RECORD:
      const {
        advice,
        comment,
        image,
        recall_date,
        created_at,
        contactlense,
        preliminary,
        ocularhealth,
        refraction,
        supplementary,
        additional_test,
        recommendations,
        pre_screen,
        referral,
      } = action.payload.record;

      try {
        pre_screen.focimeter = JSON.parse(pre_screen.focimeter_r_sph);
      } catch {
        pre_screen.focimeter = [{
          svd: false,
          svn: false,
          svi: false,
          vari: false,
          bif: false,
          focimeter_r_sph: "",
          focimeter_r_cyl: "",
          focimeter_r_axis: "",
          focimeter_r_add: "",
          focimeter_l_sph: "",
          focimeter_l_cyl: "",
          focimeter_l_axis: "",
          focimeter_l_add: "",
        }];
      }

      return {
        ...state,
        clinical_record: action.payload.record,
        preScreen: pre_screen,
        historyAndSymptoms: preliminary,
        ocularHealth: ocularhealth,
        subjective: refraction,
        supplementary: supplementary,
        additionalTest: additional_test,
        recommendations: recommendations,
        referral: referral,
        contactLenses: contactlense ? contactlense : state.contactLenses,
        commentAdvice: {
          ...state.commentAdvice,
          date: recall_date,
          final_comment: comment,
          final_advice: advice,
          advice,
          comment,
          recall_date,
          image,
          created_at,
        },
        loading_clinical_record: false,
        clinical_records_error: null,
      };
    case ADD_CLINICAL_RECORD:
      return {
        ...state,
        clinical_records: [...state.clinical_records, action.payload],
        loading_add_clinical_record: false,
        add_clinical_record_errors: null,
      };
    // case UPDATE_CLINICAL_RECORD:
    //   return {
    //     ...state,
    //     loading_add_clinical_record: false,
    //     add_clinical_record_errors: null,
    //   };
    case UPDATE_CLINICAL_RECORD:
      let clinical_record_index = state.clinical_records.findIndex(
        (x) => x.id === action.payload.id
      );
      const clinical_recordUpdate = [...state.clinical_records];
      clinical_recordUpdate[clinical_record_index] = action.payload;
      return {
        ...state,
        clinical_records: [...clinical_recordUpdate],
        loading_update_clinical_record: false,
        update_clinical_record_errors: null,
        loading_add_clinical_record: false,
        add_clinical_record_errors: null,
      };
    case DELETE_CLINICAL_RECORD:
      let del = state.clinical_records.findIndex(
        (x) => x.id === action.payload.id
      );
      return {
        ...state,
        clinical_records: state.clinical_records.filter(
          (item, index) => index !== del
        ),
        loading_delete_clinical_record: false,
        delete_clinical_record_errors: null,
      };
    case GET_CLINICAL_RECORDS_ERROR:
      return {
        ...state,
        loading_clinical_records: false,
        clinical_records_errors: action.payload,
      };
    case GET_CLINICAL_RECORD_ERROR:
      return {
        ...state,
        loading_clinical_record: false,
        clinical_record_errors: action.payload,
      };
    case ADD_CLINICAL_RECORD_ERROR:
      return {
        ...state,
        loading_add_clinical_record: false,
        add_clinical_record_errors: action.payload,
      };
    case UPDATE_CLINICAL_RECORD_ERROR:
      return {
        ...state,
        loading_update_clinical_record: false,
        update_clinical_record_errors: action.payload,
      };
    case DELETE_CLINICAL_RECORD_ERROR:
      return {
        ...state,
        loading_delete_clinical_record: false,
        delete_clinical_record_errors: action.payload,
      };
    case UPDATE_HISTORY_AND_SYMPTOMS:
      return {
        ...state,
        historyAndSymptoms: action.payload,
      };

      case UPDATE_REFERRAL:
      return {
        ...state,
        referral: action.payload,
      };

    case UPDATE_PRE_SCREEN:
      return {
        ...state,
        preScreen: action.payload,
      };
    case UPDATE_SUPPLEMENTARY:
      return {
        ...state,
        supplementary: action.payload,
      };
    case UPDATE_ADDITIONAL_TEST:
      return {
        ...state,
        additionalTest: action.payload,
      };
    case UPDATE_RECOMMENDATIONS:
      return {
        ...state,
        recommendations: action.payload,
      };
    case UPDATE_OCULAR_HEALTH:
      return {
        ...state,
        ocularHealth: action.payload,
      };
    case UPDATE_SUBJECTIVE:
      return {
        ...state,
        subjective: action.payload,
      };
    case UPDATE_COMMENT_ADVICE:
      return {
        ...state,
        commentAdvice: action.payload,
      };
    case UPDATE_DRAWING_SECTION:
      return {
        ...state,
        drawingSection: action.payload,
      };
    case UPDATE_IMAGE_SECTION:
      return {
        ...state,
        imageSection: action.payload,
      };
    case UPDATE_MUSCLE_BALANCE_DRAWING_SECTION:
      return {
        ...state,
        muscleBalanceDrawingSection: action.payload,
      };
    case UPDATE_MUSCLE_BALANCE_UPDATE_IMAGE_SECTION:
      return {
        ...state,
        muscleBalanceImageSection: action.payload,
      };
    case UPDATE_CONTACT_LENSES:
      return {
        ...state,
        contactLenses: action.payload,
      };
    case SET_PATIENT_CLINICAL_STATUS:
      return {
        ...state,
        addClinicalRecord: action.payload,
        clinical_record: {},
        historyAndSymptoms: {
          reason: "",
          visions: "",
          rx: "",
          lee: "",
          lme: "",
          vdu: "",
          hours: "",
          smoker: "",
          health: "",
          medication: "",
          family_ocular: "",
          occupation: "",
          hobbies: "",
          ocular: "",
          dm: false,
          glaucoma: false,
          amb: false,
          family_history: "",
          h_or_a: false,
          dip: false,
          flashes: false,
          floaters: false,
          cl: false,
          driver: false,
          notes: "",
          eyestrain: false
        },
        referral: {
          patient_details: "",
          gp_details: "",
          referral_urgency: "",
          referral_reasons: "",
          iop_re: "",
          iop_le: "",
          disc_appearance_re: "",
          disc_appearance_le: "",
          field_results_le: "",
          field_results_re: "",
          notes: ""
        },
        preScreen: {
          notes: "",
          time: "",
          staff_name: "",
          r_k_readings: "",
          l_k_readings: "",
          nonContact: false,
          goldmann: false,
          iCare: false,
          r_iop1: "",
          r_iop2: "",
          r_iop3: "",
          r_iopAv: "",
          l_iop1: "",
          l_iop2: "",
          l_iop3: "",
          l_iopAv: "",
          focimeter: [{
            svd: false,
            svn: false,
            svi: false,
            vari: false,
            bif: false,
            focimeter_r_sph: "",
            focimeter_r_cyl: "",
            focimeter_r_axis: "",
            focimeter_r_add: "",
            focimeter_l_sph: "",
            focimeter_l_cyl: "",
            focimeter_l_axis: "",
            focimeter_l_add: "",
          }],
          svd: false,
          svn: false,
          svi: false,
          vari: false,
          bif: false,
          focimeter_r_sph: "",
          focimeter_r_cyl: "",
          focimeter_r_axis: "",
          focimeter_r_add: "",
          focimeter_l_sph: "",
          focimeter_l_cyl: "",
          focimeter_l_axis: "",
          focimeter_l_add: "",
          addAnotherPair: "",
          refractor_r_sph: "",
          refractor_r_cyl: "",
          refractor_r_axis: "",
          refractor_l_sph: "",
          refractor_l_cyl: "",
          refractor_l_axis: "",
          drugName: "",
          iopPre: "",
          preTime: "",
          batchNo: "",
          expiryDate: "",
          timeInstallation: "",
          iopPost: "",
          postTime: ""
        },
        supplementary: {
          dvWith: "",
          dvWithMovement: "",
          dvWithout: "",
          dvWithoutMovement: "",
          nvWith: "",
          nvWithMovement: "",
          nvWithout: "",
          nvWithoutMovement: "",
          dvHorizontal: "",
          dvVertical: "",
          nvVertical: "",
          nvHorizontal: "",
          accomodation: "",
          motility: "",
          pupils: "",
          npc: "",
          notes: "",
        },
        recommendations: {
          adviceGiven: "",
          lensRecommendation: "",
          lensUpgrade: "",
          recall: "",
          testType1: "",
          testType2: "",
          advice: "",
        },
        additionalTest: {
          ishihara_normal: false,
          ishihara: "",
          amsler_normal: false,
          amsler: "",
          stereopsis_normal: false,
          stereopsis: "",
          not_required: false,
          completed: false,
          full_threshold: false,
          supra_threshold: false,
          confrontation: false,
          r_visual_fields: "",
          l_visual_fields: "",
          notes: "",
        },
        ocularHealth: {
          r_anterior_drawing: {
            paths: [],
            image: null
          },
          l_anterior_drawing: {
            paths: [],
            image: null
          },
          r_posterior_drawing: {
            paths: [],
            image: null
          },
          l_posterior_drawing: {
            paths: [],
            image: null
          },
          r_extarnal: "",
          r_lids_lashes: "",
          r_conjunctiva: "",
          r_cornea: "",
          r_anterior_chamber: "",
          r_lens: "",
          r_vitreous: "",
          r_discs: "",
          r_c_d: "",
          r_vessels: "",
          r_av: "",
          r_macula: "",
          r_fundus: "",
          r_notes: "",
          l_extarnal: "",
          l_lids_lashes: "",
          l_conjunctiva: "",
          l_cornea: "",
          l_anteriol_chamber: "",
          l_lens: "",
          l_vitreous: "",
          l_discs: "",
          l_c_d: "",
          l_vessels: "",
          l_av: "",
          l_macula: "",
          l_fundus: "",
          l_notes: "",
          predialtion_time: "",
          predialtion_tonometry_r: "",
          predialtion_tonometry_l: "",
          predialtion_tonometry_instrument: "",
          postdialtion_time: "",
          postdialtion_tonometry_r: "",
          postdialtion_tonometry_l: "",
          postdialtion_tonometry_instrument: "",
          ocular_health_note: "",
          drawing_notes: "",
        },
        subjective: {
          r_current_va: "",
          l_current_va: "",
          sx_r_vision: "",
          sx_r_bv: "",
          sx_r_sph: "+0.00",
          sx_r_cyl: "+0.00",
          sx_r_axis: "0",
          sx_r_dva: "",
          sx_r_dvb: "",
          sx_r_add: "",
          sx_r_nva: "",
          sx_r_iv_add: "",
          sx_r_pd: "",
          sx_r_bvd: "",
          sx_l_vision: "",
          sx_l_bv: "",
          sx_l_sph: "+0.00",
          sx_l_cyl: "+0.00",
          sx_l_axis: "0",
          sx_l_dva: "",
          sx_l_dvb: "",
          sx_l_add: "",
          sx_l_nva: "",
          sx_l_iv_add: "",
          sx_l_pd: "",
          sx_l_bvd: "",
          px_r_vision: "",
          px_r_bv: "",
          px_r_sph: "+0.00",
          px_r_cyl: "+0.00",
          px_r_axis: "0",
          px_r_dva: "",
          px_r_dvb: "",
          px_r_add: "",
          px_r_nva: "",
          px_r_iv_add: "",
          px_r_pd: "",
          px_r_bvd: "",
          px_l_vision: "",
          px_l_bv: "",
          px_l_sph: "+0.00",
          px_l_cyl: "+0.00",
          px_l_axis: "0",
          px_l_dva: "",
          px_l_dvb: "",
          px_l_add: "",
          px_l_nva: "",
          px_l_iv_add: "",
          px_l_pd: "",
          px_l_bvd: "",
          sx_dp_r_h: "",
          sx_dp_r_v: "",
          sx_dp_l_h: "",
          sx_dp_l_v: "",
          sx_np_r_h: "",
          sx_np_r_v: "",
          sx_np_l_h: "",
          sx_np_l_v: "",
          px_dp_r_h: "",
          px_dp_r_v: "",
          px_dp_l_h: "",
          px_dp_l_v: "",
          px_np_r_h: "",
          px_np_r_v: "",
          px_np_l_h: "",
          px_np_l_v: "",
        },
        commentAdvice: {
          date: new Date(),
          final_comment: "",
          final_advice: "",
          recallcategoryid: null,
          type: null,
          comments: "",
        },
        drawingSection: {
          paths: [],
        },
        imageSection: {
          image: null,
        },
        muscleBalanceDrawingSection: {
          paths: [],
        },
        muscleBalanceImageSection: {
          image: null,
        },
        contactLenses: {
          r_extarnal: "",
          r_lids_lashes: "",
          r_conjunctiva: "",
          r_cornea: "",
          r_anterior_chamber: "",
          r_lens: "",
          r_vitreous: "",
          r_discs: "",
          r_c_d: "",
          r_vessels: "",
          r_av: "",
          r_macula: "",
          r_fundus: "",
          r_notes: "",
          l_extarnal: "",
          l_lids_lashes: "",
          l_conjunctiva: "",
          l_cornea: "",
          l_anteriol_chamber: "",
          l_lens: "",
          l_vitreous: "",
          l_discs: "",
          l_c_d: "",
          l_vessels: "",
          l_av: "",
          l_macula: "",
          l_fundus: "",
          l_notes: "",
          r_sph: "+0.00",
          r_cyl: "+0.00",
          r_axis: "0",
          r_add: "",
          r_cl_fit: "",
          l_sph: "+0.00",
          l_cyl: "+0.00",
          l_axis: "0",
          l_add: "",
          l_cl_fit: "",
          prescription_type: "",
          recommendation: "",
        },
      };
    case SELECT_CLINICAL_RECORD:
      return {
        ...state,
        selectedRecord: action.payload,
      };

    default:
      return state;
  }
}
