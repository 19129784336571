import {
    SET_TRANSACTIONS,
    LOADING_GET_TRANSACTIONS,
    GET_TRANSACTIONS_ERROR,
    UPDATE_TRANSACTION,
    LOADING_UPDATE_TRANSACTION,
    UPDATE_TRANSACTION_ERROR,
    DELETE_TRANSACTION,
    LOADING_DELETE_TRANSACTION,
    DELETE_TRANSACTION_ERROR,
    ADD_TRANSACTION,
    LOADING_ADD_TRANSACTION,
    ADD_TRANSACTION_ERROR
    } from '../types';
    
    const initialState = {
      transactions: [],
      transaction: {},
      loading_get_transactions: false,
      get_transactions_errors:null,
      loading_add_transactions: false,
      add_transactions_errors:null,
      loading_update_transactions: false,
      update_transactions_errors:null,
      loading_delete_transactions: false,
      delete_transactions_errors:null,
  
    };
    
    export default function(state = initialState, action) {
      switch (action.type) {
        case LOADING_GET_TRANSACTIONS:
          return {
            ...state,
            loading_get_transactions: true,
            get_transactions_errors:null
          };
        case LOADING_ADD_TRANSACTION:
          return {
            ...state,
            loading_add_transactions: true,
            add_transactions_errors:null,
          };
        case LOADING_UPDATE_TRANSACTION:
          return {
            ...state,
            loading_update_transactions: true,
            update_transactions_errors:null,
          };
        case LOADING_DELETE_TRANSACTION:
          return {
            ...state,
            loading_delete_transactions: true,
            delete_transactions_errors:null,
          };
       
        case SET_TRANSACTIONS:
          return {
            ...state,
            transactions: action.payload.data,
            loading_get_transactions: false,
            get_transactions_errors:null
          };
        case ADD_TRANSACTION:
            return {
              ...state,
              transactions:[...state.transactions,action.payload],
              loading_add_transactions: false,
              add_transactions_errors:null,
            };
        case UPDATE_TRANSACTION:
              let transaction_index = state.transactions.findIndex(x => x.id === action.payload.id);
              const transactionUpdate = [...state.transactions];
              transactionUpdate[transaction_index] = action.payload;
              return {
                ...state,
                transactions: [...transactionUpdate],
                loading_update_transactions: false,
                update_transactions_errors:null,
        };
        case DELETE_TRANSACTION:
            let del_index = state.transactions.findIndex(x => x.id === action.payload.id);
            return {
              ...state,
              transactions: state.transactions.filter((item, index) => index !== del_index),
              loading_delete_transactions: false,
              delete_transactions_errors:null,
        };
        case GET_TRANSACTIONS_ERROR:
          return {
            ...state,
            loading_get_transactions: false,
            get_transactions_errors: action.payload
          };
        case ADD_TRANSACTION_ERROR:
          return {
            ...state,
            loading_add_transactions: false,
            add_transactions_errors:action.payload.errors,
          };
        case UPDATE_TRANSACTION_ERROR:
          return {
            ...state,
            loading_update_transactions: false,
            update_transactions_errors: action.payload,
          };
        case DELETE_TRANSACTION_ERROR:
          return {
            ...state,
            loading_delete_transactions: false,
            delete_transactions_errors: action.payload,
          };
        
        default:
          return state;
      }
    }
    