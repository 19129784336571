import {
  LOADING_LUNCH_BREAK,
  SET_LUNCH_BREAKS,
  LUNCH_BREAK_ERRORS,
  ADD_LUNCH_BREAK,
  LOADING_ADD_LUNCH_BREAK,
  ADD_LUNCH_BREAK_ERRORS,
  UPDATE_LUNCH_BREAK,
  UPDATE_LUNCH_BREAK_ERRORS,
  LOADING_UPDATE_LUNCH_BREAK,
} from "../types";

const initialState = {
  lunchBreaks: [],
  loading_lunch_breaks: false,
  lunch_break_errors: null,
  loading_add_lunch_break: false,
  add_lunch_break_errors: null,
  loading_update_lunch_break: false,
  update_lunch_break_errors: null,
  loading_delete_lunch_break: false,
  delete_lunch_break_errors: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_LUNCH_BREAK:
      return {
        ...state,
        loading_lunch_breaks: true,
        lunch_break_errors: null,
      };
    case LOADING_UPDATE_LUNCH_BREAK:
      return {
        ...state,
        loading_update_lunch_break: true,
        update_lunch_break_errors: null,
      };
    case LOADING_ADD_LUNCH_BREAK:
      return {
        ...state,
        loading_add_lunch_break: true,
        add_lunch_break_errors: null,
      };
    case SET_LUNCH_BREAKS:
      return {
        ...state,
        lunchBreaks: action.payload,
        loading_lunch_breaks: false,
        lunch_break_errors: null,
      };
    case ADD_LUNCH_BREAK:
      return {
        ...state,
        // lunchBreaks: [...state.lunchBreaks, action.payload],
        loading_add_lunch_break: false,
        add_lunch_break_errors: null,
      };
    case UPDATE_LUNCH_BREAK:
      let que_index = state.lunchBreaks.findIndex(
        (x) => x.id === action.payload.id
      );
      const queUpdate = [...state.lunchBreaks];
      queUpdate[que_index] = action.payload;
      return {
        ...state,
        lunchBreaks: [...queUpdate],
        loading_update_lunch_break: false,
        update_lunch_break_errors: null,
        loading_add_lunch_break: false,
        add_lunch_break_errors: null,
      };
    case LUNCH_BREAK_ERRORS:
      return {
        ...state,
        loading_lunch_breaks: false,
        lunch_break_errors: action.payload,
      };
    case ADD_LUNCH_BREAK_ERRORS:
      return {
        ...state,
        loading_add_lunch_break: false,
        add_lunch_break_errors: action.payload,
      };
    case UPDATE_LUNCH_BREAK_ERRORS:
      return {
        ...state,
        loading_update_lunch_break: false,
        update_lunch_break_errors: action.payload,
      };
    default:
      return state;
  }
}
