import {
    SET_PAYMENTS,
    LOADING_GET_PAYMENTS,
    GET_PAYMENTS_ERROR,
    UPDATE_PAYMENT,
    LOADING_UPDATE_PAYMENT,
    UPDATE_PAYMENT_ERROR,
    DELETE_PAYMENT,
    LOADING_DELETE_PAYMENT,
    DELETE_PAYMENT_ERROR,
    ADD_PAYMENT,
    LOADING_ADD_PAYMENT,
    ADD_PAYMENT_ERROR
    } from '../types';
    
    const initialState = {
      payments: [],
      payment: {},
      loading_get_payments: false,
      get_payments_errors:null,
      loading_add_payments: false,
      add_payments_errors:null,
      loading_update_payments: false,
      update_payments_errors:null,
      loading_delete_payments: false,
      delete_payments_errors:null,
  
    };
    
    export default function(state = initialState, action) {
      switch (action.type) {
        case LOADING_GET_PAYMENTS:
          return {
            ...state,
            loading_get_payments: true,
            get_payments_errors:null
          };
        case LOADING_ADD_PAYMENT:
          return {
            ...state,
            loading_add_payments: true,
            add_payments_errors:null,
          };
        case LOADING_UPDATE_PAYMENT:
          return {
            ...state,
            loading_update_payments: true,
            update_payments_errors:null,
          };
        case LOADING_DELETE_PAYMENT:
          return {
            ...state,
            loading_delete_payments: true,
            delete_payments_errors:null,
          };
       
        case SET_PAYMENTS:
          return {
            ...state,
            payments: action.payload.data,
            loading_get_payments: false,
            get_payments_errors:null
          };
        case ADD_PAYMENT:
            return {
              ...state,
              payments:[...state.payments,action.payload],
              loading_add_payments: false,
              add_payments_errors:null,
            };
        case UPDATE_PAYMENT:
              let PAYMENT_index = state.payments.findIndex(x => x.id === action.payload.id);
              const PAYMENTUpdate = [...state.payments];
              PAYMENTUpdate[PAYMENT_index] = action.payload;
              return {
                ...state,
                payments: [...PAYMENTUpdate],
                loading_update_payments: false,
                update_payments_errors:null,
        };
        case DELETE_PAYMENT:
            let del_index = state.payments.findIndex(x => x.id === action.payload.id);
            return {
              ...state,
              payments: state.payments.filter((item, index) => index !== del_index),
              loading_delete_payments: false,
              delete_payments_errors:null,
        };
        case GET_PAYMENTS_ERROR:
          return {
            ...state,
            loading_get_payments: false,
            get_payments_errors: action.payload
          };
        case ADD_PAYMENT_ERROR:
          return {
            ...state,
            loading_add_payments: false,
            add_payments_errors:action.payload.errors,
          };
        case UPDATE_PAYMENT_ERROR:
          return {
            ...state,
            loading_update_payments: false,
            update_payments_errors: action.payload,
          };
        case DELETE_PAYMENT_ERROR:
          return {
            ...state,
            loading_delete_payments: false,
            delete_payments_errors: action.payload,
          };
        
        default:
          return state;
      }
    }
    