import {
  LOADING_STORE,
  SET_STORES,
  STORE_ERRORS,
  ADD_STORE,
  ADD_STORE_ERRORS,
  LOADING_ADD_STORE,
  LOADING_DELETE_STORE,
  DELETE_STORE,
  DELETE_STORE_ERRORS,
  LOADING_UPDATE_STORE,
  UPDATE_STORE,
  UPDATE_STORE_ERRORS,
} from "../types";

const initialState = {
  stores: [],
  store: {},
  loading_stores: false,
  loading_delete_store: false,
  delete_store_errors: null,
  store_errors: null,
  links: "",
  meta: 0,
  loading_add_store: false,
  add_store_errors: null,
  loadingUpdateBrand: false,
  updateBrandErrors: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_STORE:
      return {
        ...state,
        loading_stores: true,
      };

    case SET_STORES:
      return {
        ...state,
        stores: action.payload.data,
        links: action.payload.next_page_url,
        meta: action.payload.total,
        loading_stores: false,
        store_errors: null,
      };
    case STORE_ERRORS:
      return {
        ...state,
        store_errors: action.payload,
        loading_stores: false,
      };
    case LOADING_ADD_STORE:
      return {
        ...state,
        loading_add_store: true,
        add_store_errors: null,
      };

    case ADD_STORE:
      return {
        ...state,
        stores: [action.payload, ...state.stores],
        loading_add_store: false,
        add_store_errors: null,
      };
    case ADD_STORE_ERRORS:
      return {
        ...state,
        loading_add_store: false,
        add_store_errors: action.payload.errors,
      };

    case LOADING_UPDATE_STORE:
      return {
        ...state,
        loadingUpdateBrand: true,
        updateBrandErrors: null,
      };

    case UPDATE_STORE:
      let storeIndex = state.stores.findIndex(
        (x) => x.id === action.payload.id
      );
      const updatedBrands = [...state.stores];
      updatedBrands[storeIndex] = action.payload;
      return {
        ...state,
        stores: [...updatedBrands],
        loadingUpdateBrand: false,
        updateBrandErrors: null,
      };

    case UPDATE_STORE_ERRORS:
      return {
        ...state,
        loadingUpdateBrand: false,
        updateBrandErrors: action.payload.errors,
      };
    case LOADING_DELETE_STORE:
      return {
        ...state,
        loading_delete_store: true,
        delete_store_errors: null,
      };
    case DELETE_STORE:
      let del = state.stores.findIndex((x) => x.id === action.payload.id);
      return {
        ...state,
        stores: state.stores.filter((item, index) => index !== del),
        loading_delete_store: false,
        delete_store_errors: null,
      };
    case DELETE_STORE_ERRORS:
      return {
        ...state,
        loading_add_store: false,
        add_store_errors: action.payload,
      };
    default:
      return state;
  }
}
