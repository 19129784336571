import {
    SET_CATEGORIES,
    LOADING_CATEGORIES,
    CATEGORIES_ERRORS
  } from '../types';
  
  const initialState = {
    categories: [],
    category: {},
    loading_categories: false,
    categories_errors: null,
    links: '',
     meta: 0,
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case LOADING_CATEGORIES:
        return {
          ...state,
          loading_categories: true
        };
     
      case SET_CATEGORIES:
        return {
          ...state,
          categories: action.payload.data,
          links: action.payload.next_page_url,
          meta: action.payload.total,
          loading_categories: false,
          categories_errors: null
        };
      case CATEGORIES_ERRORS:
        return {
          ...state,
          categories_errors: action.payload,
          loading_categories: false,
        };
      default:
        return state;
    }
  }
  