import React from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import ServiceWorkerWrapper from "./ServiceWorkerWrapper";
import CustomThemeProvider from "./theming/CustomThemeProvider";

const root = ReactDOM.createRoot(document.querySelector("#root"));

root.render(
    <CustomThemeProvider>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <ServiceWorkerWrapper />
    </CustomThemeProvider>
);