import {
  LOADING_LIVE_QUE,
  SET_LIVE_QUES,
  LIVE_QUE_ERRORS,
  ADD_LIVE_QUE,
  LOADING_ADD_LIVE_QUE,
  ADD_LIVE_QUE_ERRORS,
  UPDATE_LIVE_QUE,
  UPDATE_LIVE_QUE_ERRORS,
  LOADING_UPDATE_LIVE_QUE,
  // DELETE_LIVE_QUE,
  // LOADING_DELETE_LIVE_QUE,
  // DELETE_LIVE_QUE_ERRORS,
} from "../types";

const initialState = {
  liveQues: [],
  loading_live_ques: false,
  live_que_errors: null,
  loading_add_live_que: false,
  add_live_que_errors: null,
  loading_update_live_que: false,
  update_live_que_errors: null,
  loading_delete_live_que: false,
  delete_live_que_errors: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_LIVE_QUE:
      return {
        ...state,
        loading_live_ques: true,
        live_que_errors: null,
      };
    case LOADING_UPDATE_LIVE_QUE:
      return {
        ...state,
        loading_update_live_que: true,
        update_live_que_errors: null,
      };
    // case LOADING_DELETE_LIVE_QUE:
    //   return {
    //     ...state,
    //     loading_delete_live_que: true,
    //     delete_live_que_errors: null,
    //   };
    case LOADING_ADD_LIVE_QUE:
      return {
        ...state,
        loading_add_live_que: true,
        add_live_que_errors: null,
      };
    case SET_LIVE_QUES:
      return {
        ...state,
        liveQues: action.payload,
        loading_live_ques: false,
        live_que_errors: null,
      };
    case ADD_LIVE_QUE:
      return {
        ...state,
        liveQues: [...state.liveQues, action.payload],
        loading_add_live_que: false,
        add_live_que_errors: null,
      };
    case UPDATE_LIVE_QUE:
      let que_index = state.liveQues.findIndex(
        (x) => x.id === action.payload.id
      );
      const queUpdate = [...state.liveQues];
      queUpdate[que_index] = action.payload;
      return {
        ...state,
        liveQues: [...queUpdate],
        loading_update_live_que: false,
        update_live_que_errors: null,
        loading_add_live_que: false,
        add_live_que_errors: null,
      };
    // case DELETE_LIVE_QUE:
    //   let del = state.liveQues.findIndex((x) => x.id === action.payload.id);
    //   return {
    //     ...state,
    //     liveQues: state.liveQues.filter((item, index) => index !== del),
    //     loading_delete_live_que: false,
    //     delete_live_que_errors: null,
    //   };
    case LIVE_QUE_ERRORS:
      return {
        ...state,
        loading_live_ques: false,
        live_que_errors: action.payload,
      };
    case ADD_LIVE_QUE_ERRORS:
      return {
        ...state,
        loading_add_live_que: false,
        add_live_que_errors: action.payload,
      };
    case UPDATE_LIVE_QUE_ERRORS:
      return {
        ...state,
        loading_update_live_que: false,
        update_live_que_errors: action.payload,
      };
    // case DELETE_LIVE_QUE_ERRORS:
    //   return {
    //     ...state,
    //     loading_delete_live_que: false,
    //     delete_live_que_errors: action.payload,
    //   };
    default:
      return state;
  }
}
