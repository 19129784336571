import {
  LOADING_GET_CLINICS,
  LOADING_UPDATE_CLINICS,
  LOADING_DELETE_CLINICS,
  LOADING_ADD_CLINICS,
  SET_CLINICS,
  ADD_CLINICS,
  UPDATE_CLINICS,
  DELETE_CLINICS,
  GET_CLINICS_ERROR,
  ADD_CLINICS_ERROR,
  UPDATE_CLINICS_ERROR,
  DELETE_CLINICS_ERROR,
  } from '../types';
  
  const initialState = {
    clinics: [],
    loading_clinics: false,
    clinics_error: null,
    loading_add_clinics: false,
    add_clinics_error : null,
    loading_update_clinics: false,
    update_clinics_error: null,
    loading_delete_clinics: false,
    delete_clinics_error: null,
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case LOADING_GET_CLINICS:
        return {
          ...state,
          loading_clinics: true,
          clinics_error: null,
        };
      case LOADING_UPDATE_CLINICS:
        return {
          ...state,
          loading_update_clinics: true,
          update_clinics_error: null,
        };
      case LOADING_DELETE_CLINICS:
        return {
          ...state,
          loading_delete_clinics: true,
          delete_clinics_error: null,
        };
      case LOADING_ADD_CLINICS:
        return {
          ...state,
          loading_add_clinics: true,
          add_clinics_error : null,
        };
      case SET_CLINICS:
        return {
          ...state,
          clinics: action.payload.data,
          loading_clinics: false,
          clinics_error: null,
        };
      case ADD_CLINICS:
          return {
            ...state,
            clinics:[...state.clinics,action.payload],
            loading_add_clinics: false,
            add_clinics_error : null,
          };
      case UPDATE_CLINICS:
        return {
          ...state,
          loading_update_clinics: false,
          update_clinics_error: null,
          loading_add_clinics: false,
          add_clinics_error : null,
        };
      case DELETE_CLINICS:
          // let del = state.clinics.findIndex(x => x.id === action.payload.id);
          return {
            ...state,
            // clinics: state.clinics.filter((item, index) => index !== del),
            loading_delete_clinics: false,
            delete_clinics_error: null,
          };
      case GET_CLINICS_ERROR:
        return {
          ...state,
          loading_clinics: false,
        };
      case ADD_CLINICS_ERROR:
        return {
          ...state,
          loading_add_clinics: false,
        };
      case UPDATE_CLINICS_ERROR:
        return {
          ...state,
          loading_update_clinics: false,
        };
      case DELETE_CLINICS_ERROR:
        return {
          ...state,
          loading_delete_clinics: false,
        };
      
      default:
        return state;
    }
  }
  