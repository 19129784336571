import {
    LOADING_GET_TAXES,
    SET_TAXES,
    GET_TAXES_ERROR,
    ADD_TAX,
    LOADING_ADD_TAX,
    ADD_TAX_ERROR,
    UPDATE_TAX,
    UPDATE_TAX_ERROR,
    LOADING_UPDATE_TAX,
    DELETE_TAX,
    LOADING_DELETE_TAX,
    DELETE_TAX_ERROR,
    } from '../types';
    
    const initialState = {
      taxes: [],
      loading_taxes: false,
      taxes_errors: null,
      loading_add_tax: false,
      add_tax_errors : null,
      loading_update_tax: false,
      update_tax_errors: null,
      loading_delete_tax: false,
      delete_tax_errors: null,
    };
    
    export default function(state = initialState, action) {
      switch (action.type) {
        case LOADING_GET_TAXES:
          return {
            ...state,
            loading_taxes: true,
            taxes_errors: null,
          };
        case LOADING_UPDATE_TAX:
          return {
            ...state,
            loading_update_tax: true,
            update_tax_errors: null,
          };
        case LOADING_DELETE_TAX:
          return {
            ...state,
            loading_delete_tax: true,
            delete_tax_errors: null,
          };
        case LOADING_ADD_TAX:
          return {
            ...state,
            loading_add_tax: true,
            add_tax_errors : null,
          };
        case SET_TAXES:
          return {
            ...state,
            taxes: action.payload.data,
            loading_taxes: false,
            taxes_errors: null,
          };
        case ADD_TAX:
            return {
              ...state,
              taxes:[...state.taxes,action.payload],
              loading_add_tax: false,
              add_tax_errors : null,
            };
        case UPDATE_TAX:
          let tax_index = state.taxes.findIndex(x => x.id === action.payload.id);
          const taxUpdate = [...state.taxes];
          taxUpdate[tax_index] = action.payload;
          return {
            ...state,
            taxes: [...taxUpdate],
            loading_update_tax: false,
            update_tax_errors: null,
            loading_add_tax: false,
            add_tax_errors : null,
          };
        case DELETE_TAX:
            let del = state.taxes.findIndex(x => x.id === action.payload.id);
            return {
              ...state,
              taxes: state.taxes.filter((item, index) => index !== del),
              loading_delete_tax: false,
              delete_tax_errors: null,
            };
        case GET_TAXES_ERROR:
          return {
            ...state,
            loading_taxes: false,
            taxes_errors: action.payload,
          };
        case ADD_TAX_ERROR:
          return {
            ...state,
            loading_add_tax: false,
            add_tax_errors : action.payload,
          };
        case UPDATE_TAX_ERROR:
          return {
            ...state,
            loading_update_tax: false,
            update_tax_errors: action.payload,
          };
        case DELETE_TAX_ERROR:
          return {
            ...state,
            loading_delete_tax: false,
            delete_tax_errors: action.payload,
          };
       
        default:
          return state;
      }
    }
    