import {
    LOADING_GET_MEDIAS,
    SET_MEDIAS,
    GET_MEDIAS_ERROR,
    ADD_MEDIA,
    LOADING_ADD_MEDIA,
    ADD_MEDIA_ERROR,
    UPDATE_MEDIA,
    UPDATE_MEDIA_ERROR,
    LOADING_UPDATE_MEDIA,
    DELETE_MEDIA,
    LOADING_DELETE_MEDIA,
    DELETE_MEDIA_ERROR,
    } from '../types';
    
    const initialState = {
      medias: [],
      loading_medias: false,
      medias_errors: null,
      loading_add_media: false,
      add_media_errors : null,
      loading_update_media: false,
      update_media_errors: null,
      loading_delete_media: false,
      delete_media_errors: null,
    };
    
    export default function(state = initialState, action) {
      switch (action.type) {
        case LOADING_GET_MEDIAS:
          return {
            ...state,
            loading_medias: true,
            medias_errors: null,
          };
        case LOADING_UPDATE_MEDIA:
          return {
            ...state,
            loading_update_media: true,
            update_media_errors: null,
          };
        case LOADING_DELETE_MEDIA:
          return {
            ...state,
            loading_delete_media: true,
            delete_media_errors: null,
          };
        case LOADING_ADD_MEDIA:
          return {
            ...state,
            loading_add_media: true,
            add_media_errors : null,
          };
        case SET_MEDIAS:
          return {
            ...state,
            medias: action.payload.data,
            loading_medias: false,
            medias_errors: null,
          };
        case ADD_MEDIA:
            return {
              ...state,
              medias:[...state.medias,action.payload],
              loading_add_media: false,
              add_media_errors : null,
            };
        case UPDATE_MEDIA:
          let media_index = state.medias.findIndex(x => x.id === action.payload.id);
          const mediaUpdate = [...state.medias];
          mediaUpdate[media_index] = action.payload;
          return {
            ...state,
            medias: [...mediaUpdate],
            loading_update_media: false,
            update_media_errors: null,
            loading_add_media: false,
            add_media_errors : null,
          };
        case DELETE_MEDIA:
            let del = state.medias.findIndex(x => x.id === action.payload.id);
            return {
              ...state,
              medias: state.medias.filter((item, index) => index !== del),
              loading_delete_media: false,
              delete_media_errors: null,
            };
        case GET_MEDIAS_ERROR:
          return {
            ...state,
            loading_medias: false,
            medias_errors: action.payload,
          };
        case ADD_MEDIA_ERROR:
          return {
            ...state,
            loading_add_media: false,
            // add_media_errors : action.payload,
          };
        case UPDATE_MEDIA_ERROR:
          return {
            ...state,
            loading_update_media: false,
            update_media_errors: action.payload,
          };
        case DELETE_MEDIA_ERROR:
          return {
            ...state,
            loading_delete_media: false,
            delete_media_errors: action.payload,
          };
       
        default:
          return state;
      }
    }
    