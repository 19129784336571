import {
    ADD_DIRECTDEBIT,
    GET_ALLDIRECTDEBIT,
    GET_DIRECTDEBIT,
    LOADING_DIRECTDEBIT,
    STOP_LOADING_DIRECTDEBIT, UPDATE_DIRECTDEBIT
} from "../types";

const initialState = {
    directDebits: [],
    loading_state: false,
}


export default function (state = initialState, action) {
    switch (action.type) {

        case ADD_DIRECTDEBIT:
            return {
                ...state,
                directDebits: [...state.directDebits, action.payload],
                // loading_state: false
            }

        case GET_DIRECTDEBIT:
            return {
                ...state,
                directDebits: action.payload.data,
                // loading_state: false,
            }

        case GET_ALLDIRECTDEBIT:
            return {
                ...state,
                directDebits: action.payload.data,
                // loading_state: false
            }
        case LOADING_DIRECTDEBIT:
            return {
                ...state,
                loading_state: true
            }
        case STOP_LOADING_DIRECTDEBIT:
            return {
                ...state,
                loading_state: false
            }

        case UPDATE_DIRECTDEBIT:
            let index = state.directDebits.findIndex(x => x.id === action.payload.id);
            const updatedDirectDebits = [...state.directDebits];
            updatedDirectDebits[index] = {
                ...updatedDirectDebits[index],
                ...action.payload,
            };
            return {
                ...state,
                directDebits: [...updatedDirectDebits],
                loading_state: false
            }
        default:
            return state
    }
}
