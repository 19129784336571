import {
   LOADING_IMPORT_PRODUCT,
   IMPORT_PRODUCT_SUCCESS,
   IMPORT_PRODUCT_ERRORS,
   LOADING_CHECK_PRODUCT,
   CHECK_PRODUCT_ERROR,
   CHECK_PRODUCT_SUCCESS,
   CLEAR_PRODUCT_CSV,

  } from '../types';
  
  const initialState = {
    product_import:[],
    loading_import_products: false,
    import_products_errors: null,
    duplicate:[],
    loading_product_check:false,
    check_product_errors:null,
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case CLEAR_PRODUCT_CSV:
        return {
          ...state,
           product_import:[],
           loading_import_products: false,
           import_products_errors: null,
           duplicate:[],
           loading_product_check:false,
           check_product_errors:null,
        };
      case LOADING_IMPORT_PRODUCT:
        return {
          ...state,
          loading_import_products: true,
          import_products_errors:null
        };
     
      case IMPORT_PRODUCT_SUCCESS:
        return {
          ...state,
          product_import:action.payload.product,
          loading_import_products: false,
          import_products_errors:null
        };
      case IMPORT_PRODUCT_ERRORS:
        return {
          ...state,
          loading_import_products: false,
          import_products_errors:action.payload
        };
      case LOADING_CHECK_PRODUCT:
            return {
              ...state,
              loading_product_check: true,
              check_product_errors:null
            };
      case CHECK_PRODUCT_ERROR:
            return {
              ...state,
              loading_product_check: true,
              check_product_errors:action.payload
            };
      case CHECK_PRODUCT_SUCCESS:
            return {
              ...state,
              duplicate:action.payload.duplicate,
              loading_product_check: false,
              check_product_errors:null
            };
      default:
        return state;
    }
  }
  