import {
    ADD_RECONCILIATION,
    GET_ALL_RECONCILIATION,
    GET_RECONCILIATION,
    LOADING_RECONCILIATION,
    STOP_LOADING_RECONCILIATION,

} from "../types";

const initialState = {
    all_reconciliation: [],
    loading_state: false,
}


export default function (state = initialState, action) {
    switch (action.type) {

        case ADD_RECONCILIATION:
            return {
                ...state,
                all_reconciliation: [...state.all_reconciliation, action.payload],
                loading_state: false
            }

        case GET_ALL_RECONCILIATION:
            return {
                ...state,
                all_reconciliation: action.payload.data,
                loading_state: false,
            }

        case GET_RECONCILIATION:
            return {
                ...state,
                all_reconciliation: action.payload.data,
                loading_state: false
            }
        case LOADING_RECONCILIATION:
            return {
                ...state,
                loading_state: true
            }
        case STOP_LOADING_RECONCILIATION:
            return {
                ...state,
                loading_state: false
            }
        default:
            return state
    }
}