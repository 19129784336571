import {
  SET_PRODUCT,
  SET_PRODUCTS,
  LOADING_PRODUCT,
  PRODUCT_ERRORS,
  LOADING_SUPPLIER,
  LOADING_TYPE,
  SET_SUPPLIERS,
  SET_TYPES,
  SUPPLIER_ERRORS,
  TYPE_ERRORS,
  ADD_SUPPLIER,
  SET_SUPPLIER,
  CLEAR_SUPPLIER,
  LOADING_VARIATIONS,
  SET_VARIATIONS,
  VARIATIONS_ERRORS,
  ADD_PRODUCT,
  LOADING_ADD_PRODUCT,
  ADD_PRODUCT_ERRORS,
  SET_MANUFACTURERS,
  LOADING_MANUFACTURER,
  MANUFACTURER_ERRORS,
  UPDATE_PRODUCT,
  LOADING_UPDATE_PRODUCT,
  UPDATE_PRODUCT_ERRORS,
  ADD_TYPE,
  LOADING_ADD_TYPE,
  ADD_TYPE_ERROR,
  LOADING_DELETE_PRODUCT,
  DELETE_PRODUCT,
  DELETE_PRODUCT_ERRORS,
} from '../types'

const initialState = {
  products: [],
  suppliers: [],
  manufacturers: [],
  product_types: [],
  products_meta: [],
  product: {},
  variantion_options: [],
  supplier: {},
  product_type: {},
  loading: false,
  total: 0,
  per_page: 0,
  variantion_options_loading: false,
  loadingProductDelete: false,
  productDeleteErrors: null,
  loading_supplier: false,
  loading_store: false,
  loading_add_product: false,
  loading_update_product: false,
  loading_add_product_type: false,
  loading_manufacturer: false,
  loading_types: false,
  errors: null,
  variantion_options_errors: null,
  supplier_errors: null,
  add_product_errors: null,
  add_product_type_errors: null,
  type_errors: null,
  store_errors: null,
  update_product_errors: null,
  manufacturer_errors: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_PRODUCT:
      return {
        ...state,
        loading: true,
      }
    case LOADING_SUPPLIER:
      return {
        ...state,
        loading_supplier: true,
      }
    case LOADING_ADD_TYPE:
      return {
        ...state,
        loading_add_product_type: true,
        add_product_type_errors: null,
      }
    case LOADING_TYPE:
      return {
        ...state,
        loading_types: true,
      }
    case LOADING_VARIATIONS:
      return {
        ...state,
        variantion_options_loading: true,
        variantion_options_errors: null,
      }
    case LOADING_ADD_PRODUCT:
      return {
        ...state,
        loading_add_product: true,
        add_product_errors: null,
      }
    case LOADING_MANUFACTURER:
      return {
        ...state,
        loading_manufacturer: true,
        manufacturer_errors: null,
      }
    case LOADING_UPDATE_PRODUCT:
      return {
        ...state,
        loading_update_product: true,
        update_product_errors: null,
      }
    case SET_PRODUCTS:
      return {
        ...state,
        products: action.payload.data,
        products_meta: action.payload,
        total: action.payload.total,
        per_page: action.payload.per_page,
        loading: false,
        errors: null,
      }
    case LOADING_DELETE_PRODUCT:
      return {
        ...state,
        loadingProductDelete: false,
        productDeleteErrors: null,
      }
    case DELETE_PRODUCT_ERRORS:
      return {
        ...state,
        loadingProductDelete: false,
        productDeleteErrors: action.payload,
      }
    case DELETE_PRODUCT:
      return {
        ...state,
        products: state.products.filter(
          (item) => item.id !== action.payload.id,
        ),
        loadingProductDelete: false,
        productDeleteErrors: null,
      }
    case SET_MANUFACTURERS:
      return {
        ...state,
        manufacturers: action.payload.data,
        loading_manufacturer: false,
        manufacturer_errors: null,
      }
    case SET_SUPPLIERS:
      return {
        ...state,
        suppliers: action.payload.data,
        loading_supplier: false,
        supplier_errors: null,
      }
    case ADD_SUPPLIER:
      return {
        ...state,
        suppliers: [...state.suppliers, action.payload],
        loading_supplier: false,
        supplier_errors: null,
      }
    case ADD_PRODUCT:
      return {
        ...state,
        products: [...state.products, action.payload],
        loading_add_product: false,
        add_product_errors: null,
      }
    case ADD_TYPE:
      return {
        ...state,
        product_types: [...state.product_types, action.payload],
        loading_add_product_type: false,
        add_product_type_errors: null,
      }

    case UPDATE_PRODUCT:
      let product_index = state.products.findIndex(
        (x) => x.id === action.payload.id,
      )
      const productUpdate = [...state.products]
      productUpdate[product_index] = action.payload
      return {
        ...state,
        products: [...productUpdate],
        product: action.payload,
        loading_update_product: false,
        update_product_errors: null,
      }
    case SET_SUPPLIER:
      return {
        ...state,
        supplier: action.payload,
        loading_supplier: false,
        supplier_errors: null,
      }
    case CLEAR_SUPPLIER:
      return {
        ...state,
        supplier: {},
      }
    case SET_TYPES:
      return {
        ...state,
        product_types: action.payload.data,
        loading_types: false,
        type_errors: null,
      }
    case SET_VARIATIONS:
      return {
        ...state,
        variantion_options: action.payload.data,
        variantion_options_loading: false,
        variantion_options_errors: null,
      }
    case SET_PRODUCT:
      return {
        ...state,
        product: action.payload,
        loading: false,
        errors: null,
      }
    case PRODUCT_ERRORS:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      }
    case TYPE_ERRORS:
      return {
        ...state,
        type_errors: action.payload,
        loading_types: false,
      }
    case SUPPLIER_ERRORS:
      return {
        ...state,
        supplier_errors: action.payload.errors,
        loading_supplier: false,
      }
    case VARIATIONS_ERRORS:
      return {
        ...state,
        variantion_options_errors: action.payload,
        variantion_options_loading: false,
      }
    case ADD_PRODUCT_ERRORS:
      return {
        ...state,
        loading_add_product: false,
        add_product_errors: action.payload.errors,
      }
    case MANUFACTURER_ERRORS:
      return {
        ...state,
        loading_manufacturer: false,
        manufacturer_errors: action.payload,
      }
    case ADD_TYPE_ERROR:
      return {
        ...state,
        loading_add_product_type: false,
        add_product_type_errors: action.payload.errors,
      }
    case UPDATE_PRODUCT_ERRORS:
      return {
        ...state,
        loading_update_product: false,
        update_product_errors: action.payload,
      }
    default:
      return state
  }
}
