import {
  UPDATE_CONTACT_LENS_HISTORY_AND_SYMPTOMS,
  UPDATE_DRAWING_SECTION,
  UPDATE_IMAGE_SECTION,
  CLEAR_CONTACT_LENS_RECORD,
  UPDATE_MUSCLE_BALANCE_UPDATE_IMAGE_SECTION,
  UPDATE_MUSCLE_BALANCE_DRAWING_SECTION,
  UPDATE_CONTACT_LENS_BASELINE_MEASUREMENTS,
  UPDATE_CONTACT_LENS_ANTERIOR_EYE_CHECK,
  UPDATE_CONTACT_LENS_FLUORESEIN_CHECK,
  UPDATE_CONTACT_LENS_CONTACT_LENS_INITIAL_ORDER,
  UPDATE_CONTACT_LENS_CONTACT_LENS_FINAL,
  UPDATE_CONTACT_LENS_CONTACT_LENS_FITTING,
  UPDATE_CONTACT_LENS_CONTACT_RECOMMENDATION,
  LOADING_GET_CONTACT_LENSES,
  LOADING_GET_CONTACT_LENS,
  LOADING_UPDATE_CONTACT_LENS,
  LOADING_DELETE_CONTACT_LENS,
  LOADING_ADD_CONTACT_LENS,
  SET_CONTACT_LENSES,
  SET_CONTACT_LENS,
  SET_PATIENT_CONTACT_LENS_STATUS,
  SELECT_CONTACT_LENS,
  ADD_CONTACT_LENS,
  ADD_CONTACT_LENS_ERROR,
  UPDATE_CONTACT_LENS,
  LOADING_GET_CONTACT_LENS_STATUS,
  SET_CONTACT_LENSES_STATUS,
  GET_CONTACT_LENS_STATUS_ERROR,
  UPDATE_CONTACT_LENS_STATUS_ERROR,
  UPDATE_CONTACT_LENS_STATUS,
  LOADING_UPDATE_CONTACT_LENS_STATUS,
} from "../types";

const emptyState = {
  historyAndSymptoms: {
    reason: "",
    visions: "",
    rx: "",
    lee: "",
    lme: "",
    vdu: "",
    hours: "",
    smoker: "",
    health: "",
    medication: "",
    family_ocular: "",
    occupation: "",
    hobbies: "",
    ocular: "",
    dm: false,
    glaucoma: false,
    amb: false,
    family_history: "",
    h_or_a: false,
    dip: false,
    flashes: false,
    floaters: false,
    cl: false,
    driver: false,
    notes: "",
    eyestrain: false,
    lens_type: "",
    hours_per_day: "",
    days_per_week: "",
    years_wearing_cl: "",
  },

  baselineMeasurement: {
    r_keratometry: "",
    l_keratometry: "",
    r_pupil_size: "",
    l_pupil_size: "",
    r_hvid: "",
    l_hvid: "",
    r_vertical_p_a: "",
    l_vertical_p_a: "",
    r_ocular_distance: "",
    l_ocular_distance: "",
    notes: "",
  },

  anteriorEyeCheck: {
    r_lid_lashes: "",
    r_tear_film :"",
    r_bulbar_c :"",
    r_palpebral_c :"",
    r_limbus :"",
    r_iris :"",
    r_cornea :"",
    r_sclera :"",
    r_ac :"",
    r_lens :"",
    l_lid_lashes :"",
    l_tear_film :"",
    l_bulbar_c :"",
    l_palpebral_c :"",
    l_limbus :"",
    l_iris :"",
    l_cornea :"",
    l_sclera :"",
    l_ac :"",
    l_lens :"",
    notes: ""
  },
  fluoresceinCheck: {
    r_tear_film_quality: "",
    r_tbut :"",
    r_corneal_staining :"",
    r_corneal_n :"",
    l_tear_film_quality: "",
    l_tbut :"",
    l_corneal_staining :"",
    l_corneal_n :"",
    notes:""
  },

  contactLensInitial: {
    px_r_vision: "",
    px_r_sph: "+0.00",
    px_r_cyl: "+0.00",
    px_r_axis: "",
    px_r_dv_va: "",
    px_r_add: "",
    px_r_nv_va: "",
    px_r_iv_add: "",
    px_r_pd: "",

    px_l_vision: "",
    px_l_sph: "+0.00",
    px_l_cyl: "+0.00",
    px_l_axis: "",
    px_l_dv_va: "",
    px_l_add: "",
    px_l_nv_va: "",
    px_l_iv_add: "",
    px_l_pd: "",

    px_bvd: "",
    px_dv_bv: "",
    px_bv: "",

  //   Distance Prism
    dp_r_horz: "",
    dp_l_horz: "",

    dp_r_vert: "",
    dp_l_vert: "",

  //   Near Prism
    np_r_horz: "",
    np_l_horz: "",

    np_r_vert: "",
    np_l_vert: "",

  //   Contact lens Measurement

    cl_r_bozr :"",
    cl_r_dia :"",
    cl_r_sph :"",
    cl_r_cyl :"",
    cl_r_axis :"",
    cl_r_near_add :"",
    cl_r_contact_lens_name :"",

    cl_l_bozr :"",
    cl_l_dia :"",
    cl_l_sph :"",
    cl_l_cyl :"",
    cl_l_axis :"",
    cl_l_near_add :"",
    cl_l_contact_lens_name :"",

    cl_solution :"",
    notes: '',
  },

  contactLensFinal: {
    clf_r_vision: "",
    clf_r_sph: "",
    clf_r_cyl: "",
    clf_r_axis: "",
    clf_r_dv_va: "",
    clf_r_dv_va2: "",
    clf_r_over_refraction: "",
    clf_r_near_add: "",
    clf_r_nv_va: "",

    clf_l_vision: "",
    clf_l_sph: "",
    clf_l_cyl: "",
    clf_l_axis: "",
    clf_l_dv_va: "",
    clf_l_dv_va2: "",
    clf_l_over_refraction: "",
    clf_l_near_add: "",
    clf_l_nv_va: "",

    clf_dv_bv2: "",
    clf_dv_bv: "",
    clf_bv: "",
    clf_nv_bv: "",

    fcl_r_vision:"",
    fcl_r_sph:"",
    fcl_r_cyl:"",
    fcl_r_axis:"",
    fcl_r_dv_va:"",
    fcl_r_near_add:"",
    fcl_r_nv_va:"",
    fcl_r_bozr:"",
    fcl_r_dia:"",
    fcl_r_contact_lens_name:"",

    fcl_l_vision:"",
    fcl_l_sph:"",
    fcl_l_cyl:"",
    fcl_l_axis:"",
    fcl_l_dv_va:"",
    fcl_l_near_add:"",
    fcl_l_nv_va:"",
    fcl_l_bozr:"",
    fcl_l_dia:"",
    fcl_l_contact_lens_name:"",

    fcl_bv:"",
    fcl_dv_bv:"",
    fcl_nv_bv:"",
    fcl_solution:"",

    notes:"",

  },

  contactLensFitting:{
    cl_r_comfort:"",
    cl_r_centration:"",
    cl_r_position:"",
    cl_r_movement_blink:"",
    cl_r_push_up:"",
    cl_r_toric_movement:"",


    cl_l_comfort:"",
    cl_l_centration:"",
    cl_l_position:"",
    cl_l_movement_blink:"",
    cl_l_push_up:"",
    cl_l_toric_movement:"",

    notes:"",
  },

  recommendation: {
    advice_radio:"",
    cleaning_regime_advice:"",
    recall:"",
    test_type:"",
    advice:"",
  },
  drawingSection: {
    paths: [],
  },
  imageSection: {
    image: null,
  },
  muscleBalanceDrawingSection: {
    paths: [],
  },
  muscleBalanceImageSection: {
    image: null,
  },
}
const initialState = {
  contact_lenses: [],
  contact_lens: {},
  addContactLens: false,
  selectedContactLens: null,
  loading_contact_lens: false,
  loading_contact_lenses: false,
  contact_lenses_errors: null,
  contact_lens_error: null,
  loading_add_contact_lens: false,
  add_contact_lens_errors: null,
  loading_update_contact_lens: false,
  update_contact_lens_errors: null,
  loading_delete_contact_lens: false,
  delete_contact_lens_errors: null,
  loading_get_contact_lens_statuses: false,
  get_contact_lens_statuses_errors:null,
  loading_update_contact_lens_status: false,
  update_contact_lens_status_error: null,
  ...emptyState
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_CONTACT_LENS_STATUS_ERROR:
      return {
        ...state,
        loading_update_contact_lens_status: false,
        update_contact_lens_status_error: action.payload,
      };
    case UPDATE_CONTACT_LENS_STATUS:
      let dispensing_status_index = state.contact_lenses.findIndex(x => x.id === action.payload.id);
      const dispensingStatusUpdate = [...state.contact_lenses];
      dispensingStatusUpdate[dispensing_status_index] = action.payload;
      return {
        ...state,
        contact_lenses: [...dispensingStatusUpdate],
        loading_update_contact_lens_status: false,
        update_contact_lens_status_error: null,
      };
    case LOADING_UPDATE_CONTACT_LENS_STATUS:
      return {
        ...state,
        loading_update_contact_lens_status: true,
        update_contact_lens_status_error: null,
      };
    case LOADING_GET_CONTACT_LENSES:
      return {
        ...state,
        loading_contact_lenses: true,
        contact_lenses_errors: null,
      };
    case LOADING_GET_CONTACT_LENS:
      return {
        ...state,
        loading_contact_lens: true,
        contact_lens_error: null,
      };
    case LOADING_UPDATE_CONTACT_LENS:
      return {
        ...state,
        loading_update_contact_lens: true,
        update_contact_lens_errors: null,
      };
    case LOADING_DELETE_CONTACT_LENS:
      return {
        ...state,
        loading_delete_contact_lens: true,
        delete_contact_lens_errors: null,
      };
    case LOADING_ADD_CONTACT_LENS:
      return {
        ...state,
        loading_add_contact_lens: true,
        add_contact_lens_errors: null,
      };
    case ADD_CONTACT_LENS:
      return {
        ...state,
        // contact_lenses: [...state.contact_lenses, action.payload],
        loading_add_contact_lens: false,
        add_contact_lens_errors: null,
      };
    case SET_CONTACT_LENSES:
      return {
        ...state,
        contact_lenses: action.payload.data,
        loading_contact_lenses: false,
        contact_lenses_errors: null,
      };
    case SET_CONTACT_LENS:
      const {
        history_and_symptoms: historyAndSymptoms,
        anterior_eye_check: anteriorEyeCheck,
        baseline_measurement: baselineMeasurement,
        fluorescein_check: fluoresceinCheck,
        contact_lens_initial: contactLensInitial,
        contact_lens_final: contactLensFinal,
        contact_lens_fitting: contactLensFitting,
        recommendation,
      } = action.payload.record;

      const parseData = (key) => {
        try {
          contactLensInitial[key] = JSON.stringify(contactLensInitial[key]);
        } catch {
          contactLensInitial[key] = null;
        }
      }

      parseData("cl_r_contact_lens_name");
      parseData("cl_l_contact_lens_name");
      parseData("cl_solution");

      return {
        ...state,
        contact_lens: action.payload.record,
        historyAndSymptoms,
        anteriorEyeCheck,
        baselineMeasurement,
        fluoresceinCheck,
        contactLensInitial,
        contactLensFinal,
        contactLensFitting,
        recommendation,
        loading_contact_lens: false,
        contact_lenses_errors: null,
      };
    case UPDATE_CONTACT_LENS:
      // let contact_lens_index = state.contact_lenses.findIndex(
      //   (x) => x.id === action.payload.id
      // );
      // const contact_lens_update = [...state.contact_lenses];
      // (~contact_lens_index) && (contact_lens_update[contact_lens_index] = action.payload);
      return {
        ...state,
        // contact_lenses: [...contact_lens_update],
        loading_update_contact_lens: false,
        update_contact_lens_errors: null,
        loading_add_contact_lens: false,
        add_contact_lens_errors: null,
      };
    case ADD_CONTACT_LENS_ERROR:
      return {
        ...state,
        loading_add_contact_lens: false,
        add_contact_lens_errors: action.payload,
      };
    case UPDATE_CONTACT_LENS_HISTORY_AND_SYMPTOMS:
      return {
        ...state,
        historyAndSymptoms: action.payload,
      };

    case UPDATE_CONTACT_LENS_BASELINE_MEASUREMENTS:
      return {
        ...state,
        baselineMeasurement: action.payload,
      };
    case UPDATE_CONTACT_LENS_ANTERIOR_EYE_CHECK:
      return {
        ...state,
        anteriorEyeCheck: action.payload,
      };
    case UPDATE_CONTACT_LENS_FLUORESEIN_CHECK:
      return {
        ...state,
        fluoresceinCheck: action.payload,
      };
    case UPDATE_CONTACT_LENS_CONTACT_LENS_INITIAL_ORDER:
      return {
        ...state,
        contactLensInitial: action.payload,
      };
    case UPDATE_CONTACT_LENS_CONTACT_LENS_FINAL:
      return {
        ...state,
        contactLensFinal: action.payload,
      };
    case UPDATE_CONTACT_LENS_CONTACT_LENS_FITTING:
      return {
        ...state,
        contactLensFitting: action.payload,
      };
    case UPDATE_CONTACT_LENS_CONTACT_RECOMMENDATION:
      return {
        ...state,
        recommendation: action.payload,
      };
    case UPDATE_DRAWING_SECTION:
      return {
        ...state,
        drawingSection: action.payload,
      };
    case UPDATE_IMAGE_SECTION:
      return {
        ...state,
        imageSection: action.payload,
      };
    case UPDATE_MUSCLE_BALANCE_DRAWING_SECTION:
      return {
        ...state,
        muscleBalanceDrawingSection: action.payload,
      };
    case UPDATE_MUSCLE_BALANCE_UPDATE_IMAGE_SECTION:
      return {
        ...state,
        muscleBalanceImageSection: action.payload,
      };
    case SET_PATIENT_CONTACT_LENS_STATUS:
      return {
        ...state,
        addContactLens: action.payload,
        loading_add_contact_lens: false,
        ...emptyState,
      };
    case CLEAR_CONTACT_LENS_RECORD:
      return {
        ...state,
        addClinicalRecord: action.payload,
        ...emptyState,
      };
    case SELECT_CONTACT_LENS:
      return {
        ...state,
        selectedContactLens: action.payload,
      };
    case LOADING_GET_CONTACT_LENS_STATUS:
      return {
        ...state,
        loading_get_contact_lens_statuses: true,
      };
    case SET_CONTACT_LENSES_STATUS:
      return {
        ...state,
        statuses: action.payload,
        loading_get_contact_lens_statuses: false,
      };
    case GET_CONTACT_LENS_STATUS_ERROR:
      return {
        ...state,
        loading_get_contact_lens_statuses: false,
      };
    default:
      return state;
  }
}
