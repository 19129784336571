import {
    LOADING_GET_VOUCHERS,
    SET_VOUCHERS,
    GET_VOUCHERS_ERROR,
    ADD_VOUCHER,
    LOADING_ADD_VOUCHER,
    ADD_VOUCHER_ERROR,
    UPDATE_VOUCHER,
    UPDATE_VOUCHER_ERROR,
    LOADING_UPDATE_VOUCHER,
    DELETE_VOUCHER,
    LOADING_DELETE_VOUCHER,
    DELETE_VOUCHER_ERROR,
    LOADING_GET_VOUCHER_OPTIONS,
    SET_VOUCHER_OPTIONS,
    GET_VOUCHER_OPTIONS_ERROR,
    ADD_VOUCHER_OPTION,
    LOADING_ADD_VOUCHER_OPTION,
    ADD_VOUCHER_OPTION_ERROR,
    UPDATE_VOUCHER_OPTION,
    UPDATE_VOUCHER_OPTION_ERROR,
    LOADING_UPDATE_VOUCHER_OPTION,
    DELETE_VOUCHER_OPTION,
    LOADING_DELETE_VOUCHER_OPTION,
    DELETE_VOUCHER_OPTION_ERROR,
    LOADING_GET_ALL_VOUCHERS,
    SET_ALL_VOUCHERS,
    GET_ALL_VOUCHERS_ERROR,
} from '../types';
    
const initialState = {
    vouchers: [],
    all_vouchers:[],
    voucher:{},
    loading_vouchers: false,
    vouchers_errors: null,
    loading_get_all_vouchers: false,
    get_all_vouchers_errors: null,
    loading_add_voucher: false,
    add_voucher_errors : null,
    loading_update_voucher: false,
    update_voucher_errors: null,
    loading_delete_voucher: false,
    delete_voucher_errors: null,
    voucher_options: [],
    loading_get_options: false,
    get_options_errors: null,
    loading_add_option: false,
    add_option_errors : null,
    loading_update_option: false,
    update_option_errors: null,
    loading_delete_option: false,
    delete_option_errors: null,

};
    
    export default function(state = initialState, action) {
      switch (action.type) {
        case LOADING_GET_VOUCHERS:
          return {
            ...state,
            loading_vouchers: true,
            vouchers_errors: null,
          };
        case LOADING_GET_ALL_VOUCHERS:
          return {
            ...state,
            loading_get_all_vouchers: true,
            get_all_vouchers_errors: null,
          };
        case LOADING_UPDATE_VOUCHER:
          return {
            ...state,
            loading_update_voucher: true,
            update_voucher_errors: null,
          };
        case LOADING_DELETE_VOUCHER:
          return {
            ...state,
            loading_delete_voucher: true,
            delete_voucher_errors: null,
          };
        case LOADING_ADD_VOUCHER:
          return {
            ...state,
            loading_add_voucher: true,
            add_voucher_errors : null,
          };
        case SET_VOUCHERS:
          return {
            ...state,
            vouchers: action.payload.data,
            loading_vouchers: false,
            vouchers_errors: null,
          };
        case SET_ALL_VOUCHERS:
          return {
            ...state,
            all_vouchers: action.payload.data,
            loading_get_all_vouchers: false,
            get_all_vouchers_errors: null,
          };
        case ADD_VOUCHER:
            return {
              ...state,
              vouchers:[...state.vouchers,action.payload],
              loading_add_voucher: false,
              add_voucher_errors : null,
            };
        case UPDATE_VOUCHER:
          let voucher_index = state.vouchers.findIndex(x => x.id === action.payload.id);
          const voucherUpdate = [...state.vouchers];
          voucherUpdate[voucher_index] = action.payload;
          return {
            ...state,
            vouchers: [...voucherUpdate],
            loading_update_voucher: false,
            update_voucher_errors: null,
            loading_add_voucher: false,
            add_voucher_errors : null,
          };
        case DELETE_VOUCHER:
            let del = state.vouchers.findIndex(x => x.id === action.payload.id);
            return {
              ...state,
              vouchers: state.vouchers.filter((item, index) => index !== del),
              loading_delete_voucher: false,
              delete_voucher_errors: null,
            };
        case GET_VOUCHERS_ERROR:
          return {
            ...state,
            loading_vouchers: false,
            vouchers_errors: action.payload,
          };
        case ADD_VOUCHER_ERROR:
          return {
            ...state,
            loading_add_voucher: false,
            add_voucher_errors : action.payload,
          };
        case UPDATE_VOUCHER_ERROR:
          return {
            ...state,
            loading_update_voucher: false,
            update_voucher_errors: action.payload,
          };
        case DELETE_VOUCHER_ERROR:
          return {
            ...state,
            loading_delete_voucher: false,
            delete_voucher_errors: action.payload,
          };
          case LOADING_GET_VOUCHER_OPTIONS:
            return {
              ...state,
              loading_get_options: true,
              get_options_errors: null,
            };
          case LOADING_UPDATE_VOUCHER_OPTION:
            return {
              ...state,
              loading_update_option: true,
              update_option_errors: null,
            };
          case LOADING_DELETE_VOUCHER_OPTION:
            return {
              ...state,
              loading_delete_option: true,
              delete_option_errors: null,
            };
          case LOADING_ADD_VOUCHER_OPTION:
            return {
              ...state,
              loading_add_option: true,
              add_option_errors : null,
            };
          case SET_VOUCHER_OPTIONS:
            return {
              ...state,
              voucher: action.payload,
              voucher_options: action.payload.options,
              loading_get_options: false,
              get_options_errors: null,
            };
          case ADD_VOUCHER_OPTION:
              return {
                ...state,
                voucher_options:[...state.voucher_options,action.payload],
                loading_add_option: false,
                add_option_errors : null,
              };
          case UPDATE_VOUCHER_OPTION:
            let option_index = state.voucher_options.findIndex(x => x.id === action.payload.id);
            const optionUpdate = [...state.voucher_options];
            optionUpdate[option_index] = action.payload;
            return {
              ...state,
              voucher_options: [...optionUpdate],
              loading_update_option: false,
              update_option_errors: null,
              loading_add_option: false,
              add_option_errors : null,
            };
          case DELETE_VOUCHER_OPTION:
              let del_option = state.voucher_options.findIndex(x => x.id === action.payload.id);
              return {
                ...state,
                voucher_options: state.voucher_options.filter((item, index) => index !== del_option),
                loading_delete_option: false,
                delete_option_errors: null,
              };
          case GET_VOUCHER_OPTIONS_ERROR:
            return {
              ...state,
              loading_get_options: false,
              get_options_errors: action.payload,
            };
          case GET_ALL_VOUCHERS_ERROR:
            return {
              ...state,
              loading_get_all_vouchers: false,
              get_all_vouchers_errors: action.payload,
            };
          case ADD_VOUCHER_OPTION_ERROR:
            return {
              ...state,
              loading_add_option: false,
              add_option_errors : action.payload,
            };
          case UPDATE_VOUCHER_OPTION_ERROR:
            return {
              ...state,
              loading_update_option: false,
              update_option_errors: action.payload,
            };
          case DELETE_VOUCHER_OPTION_ERROR:
            return {
              ...state,
              loading_delete_option: false,
              delete_option_errors: action.payload,
            };
        default:
          return state;
      }
}