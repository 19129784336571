import {ADD_STOCK_TAKE, GET_ALL_STOCK_TAKE, GET_STOCK_TAKE, LOADING_STOCK_TAKE, STOP_LOADING_STOCK_TAKE} from "../types";

const initialState = {
    all_stock_take: [],
    loading_state: false,
}


export default function (state = initialState, action) {
    switch (action.type) {

        case ADD_STOCK_TAKE:
            return {
                ...state,
                // all_stock_take: [...state.all_stock_take, action.payload],
                loading_state: false
            }

        case GET_STOCK_TAKE:
            return {
                ...state,
                all_stock_take: action.payload.data,
                loading_state: false,
            }

        case GET_ALL_STOCK_TAKE:
            return {
                ...state,
                loading_state: false
            }
        case LOADING_STOCK_TAKE:
            return {
                ...state,
                loading_state: true
            }
        case STOP_LOADING_STOCK_TAKE:
            return {
                ...state,
                loading_state: false
            }
        default:
            return state
    }
}