import {
  LOADING_PRE_TEST,
  SET_PRE_TEST,
  PRE_TEST_ERRORS,
  LOADING_ADD_PRE_TEST,
  ADD_PRE_TEST,
  ADD_PRE_TEST_ERRORS,
  LOADING_UPDATE_PRE_TEST,
  UPDATE_PRE_TEST,
  UPDATE_PRE_TEST_ERRORS,
} from "../types";

const initialState = {
  pretests: [],
  pretest: {
    notes: "",
    time: "",
    staff_name: "",
    r_k_readings: "",
    l_k_readings: "",
    nonContact: false,
    goldmann: false,
    iCare: false,
    r_iop1: "",
    r_iop2: "",
    r_iop3: "",
    r_iopAv: "",
    l_iop1: "",
    l_iop2: "",
    l_iop3: "",
    l_iopAv: "",
    focimeter: [{
      svd: false,
      svn: false,
      svi: false,
      vari: false,
      bif: false,
      focimeter_r_sph: "",
      focimeter_r_cyl: "",
      focimeter_r_axis: "",
      focimeter_r_add: "",
      focimeter_l_sph: "",
      focimeter_l_cyl: "",
      focimeter_l_axis: "",
      focimeter_l_add: "",
    }],
    svd: false,
    svn: false,
    svi: false,
    vari: false,
    bif: false,
    focimeter_r_sph: "",
    focimeter_r_cyl: "",
    focimeter_r_axis: "",
    focimeter_r_add: "",
    focimeter_l_sph: "",
    focimeter_l_cyl: "",
    focimeter_l_axis: "",
    focimeter_l_add: "",
    addAnotherPair: "",
    refractor_r_sph: "",
    refractor_r_cyl: "",
    refractor_r_axis: "",
    refractor_l_sph: "",
    refractor_l_cyl: "",
    refractor_l_axis: "",
    drugName: "",
    iopPre: "",
    preTime: "",
    batchNo: "",
    expiryDate: "",
    timeInstallation: "",
    iopPost: "",
    postTime: ""
  },
  loading_pretests: false,
  loading_delete_pretest: false,
  delete_pretest_errors: null,
  pretest_errors: null,
  links: "",
  meta: 0,
  loading_add_pretest: false,
  add_pretest_errors: null,
  loadingUpdatePretest: false,
  updatePretestErrors: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_PRE_TEST:
      return {
        ...state,
        loading_pretests: true,
      };

    case SET_PRE_TEST:
      return {
        ...state,
        pretests: action.payload.data || action.payload,
        loading_pretests: false,
        pretest_errors: null,
      };
    case PRE_TEST_ERRORS:
      return {
        ...state,
        pretest_errors: action.payload,
        loading_pretests: false,
      };
    case LOADING_ADD_PRE_TEST:
      return {
        ...state,
        loading_add_pretest: true,
        add_pretest_errors: null,
      };

    case ADD_PRE_TEST:
      const pre_screen = action.payload;
      try {
        pre_screen.focimeter = JSON.parse(pre_screen.focimeter_r_sph);
      } catch {
        pre_screen.focimeter = [{
          svd: false,
          svn: false,
          svi: false,
          vari: false,
          bif: false,
          focimeter_r_sph: "",
          focimeter_r_cyl: "",
          focimeter_r_axis: "",
          focimeter_r_add: "",
          focimeter_l_sph: "",
          focimeter_l_cyl: "",
          focimeter_l_axis: "",
          focimeter_l_add: "",
        }];
      }

      return {
        ...state,
        pretests: [pre_screen, ...state.pretests],
        pretest: initialState.pretest,
        loading_add_pretest: false,
        add_pretest_errors: null,
      };
    case ADD_PRE_TEST_ERRORS:
      return {
        ...state,
        loading_add_pretest: false,
        add_pretest_errors: action.payload.errors,
      };

    case LOADING_UPDATE_PRE_TEST:
      return {
        ...state,
        loadingUpdatePretest: true,
        updatePretestErrors: null,
      };

    case UPDATE_PRE_TEST:
      return {
        ...state,
        pretest: action.payload,
      };

    case UPDATE_PRE_TEST_ERRORS:
      return {
        ...state,
        loadingUpdatePretest: false,
        updatePretestErrors: action.payload.errors,
      };
    default:
      return state;
  }
}
