import {
    LOADING_GET_TODOS,
    SET_TODOS,
    GET_TODOS_ERROR,
    ADD_TODO,
    LOADING_ADD_TODO,
    ADD_TODO_ERROR,
    UPDATE_TODO,
    UPDATE_TODO_ERROR,
    LOADING_UPDATE_TODO,
    DELETE_TODO,
    LOADING_DELETE_TODO,
    DELETE_TODO_ERROR,
    } from '../types';
    
    const initialState = {
      todos: [],
      loading_todos: false,
      todos_errors: null,
      loading_add_todo: false,
      add_todo_errors : null,
      loading_update_todo: false,
      update_todo_errors: null,
      loading_delete_todo: false,
      delete_todo_errors: null,
    };
    
    export default function(state = initialState, action) {
      switch (action.type) {
        case LOADING_GET_TODOS:
          return {
            ...state,
            loading_todos: true,
            todos_errors: null,
          };
        case LOADING_UPDATE_TODO:
          return {
            ...state,
            loading_update_todo: true,
            update_todo_errors: null,
          };
        case LOADING_DELETE_TODO:
          return {
            ...state,
            loading_delete_todo: true,
            delete_todo_errors: null,
          };
        case LOADING_ADD_TODO:
          return {
            ...state,
            loading_add_todo: true,
            add_todo_errors : null,
          };
        case SET_TODOS:
          return {
            ...state,
            todos: action.payload.data,
            loading_todos: false,
            todos_errors: null,
          };
        case ADD_TODO:
            return {
              ...state,
              todos:[...state.todos,action.payload],
              loading_add_todo: false,
              add_todo_errors : null,
            };
        case UPDATE_TODO:
          let todo_index = state.todos.findIndex(x => x.id === action.payload.id);
          const todoUpdate = [...state.todos];
          todoUpdate[todo_index] = action.payload;
          return {
            ...state,
            todos: [...todoUpdate],
            loading_update_todo: false,
            update_todo_errors: null,
            loading_add_todo: false,
            add_todo_errors : null,
          };
        case DELETE_TODO:
            let del = state.todos.findIndex(x => x.id === action.payload.id);
            return {
              ...state,
              todos: state.todos.filter((item, index) => index !== del),
              loading_delete_todo: false,
              delete_todo_errors: null,
            };
        case GET_TODOS_ERROR:
          return {
            ...state,
            loading_todos: false,
            todos_errors: action.payload,
          };
        case ADD_TODO_ERROR:
          return {
            ...state,
            loading_add_todo: false,
            add_todo_errors : action.payload,
          };
        case UPDATE_TODO_ERROR:
          return {
            ...state,
            loading_update_todo: false,
            update_todo_errors: action.payload,
          };
        case DELETE_TODO_ERROR:
          return {
            ...state,
            loading_delete_todo: false,
            delete_todo_errors: action.payload,
          };
       
        default:
          return state;
      }
    }
    