import CONSTANTS from "../../util/CONSTANTS";

function isServerError(message = "") {

   if(message.length < 100){
      return message;
   }else{
       return CONSTANTS.MESSAGES.SERVER_ERROR;
   }
}
  
export default isServerError;
  