/**
 * Counts the characters in a string and counts emojis correctly.
 *
 * @param {string} str The string to count characters from.
 * @return {number} The number of characters in the string.
 */
function getPrescriptionObjects(data) {
    
    const new_array = [];
    let arr_data =  [];
     // This will return an array with strings "1", "2", etc.
     if (data !== null) {
        arr_data = data.split("|");

        for (let a in arr_data ) {
            new_array.push({code: arr_data[a].trim()});
        }
        
     }
   
    return new_array;
}

export default getPrescriptionObjects;
