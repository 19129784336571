import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from 'redux-persist' // imports from redux-persist
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import userReducer from "./reducers/userReducer";
import inventoryReducer from "./reducers/inventoryReducer";
import updateReducer from "./reducers/updateReducer";
import variantReducer from "./reducers/variantReducer";
import brandReducer from "./reducers/brandReducer";
import categoryReducer from "./reducers/categoryReducer";
import productReducer from "./reducers/productReducer";
import patientReducer from "./reducers/patientReducer";
import appointmentReducer from "./reducers/appointmentReducer";
import employeeReducer from "./reducers/employeeReducer";
import serviceReducer from "./reducers/serviceReducer";
import prescriptionReducer from "./reducers/prescriptionReducer";
import accountReducer from "./reducers/accountReducer";
import currencyReducer from "./reducers/currencyReducer";
import transactionReducer from "./reducers/transactionReducer";
import paymentReducer from "./reducers/paymentReducer";
import dispensingReducer from "./reducers/dispensingReducer";
import recallReducer from "./reducers/recallReducer";
import todoReducer from "./reducers/todoReducer";
import voucherReducer from "./reducers/voucherReducer";
import taxReducer from "./reducers/taxReducer";
import SaleReducer from "./reducers/SaleReducer";
import noteReducer from "./reducers/noteReducer";
import mediaReducer from "./reducers/mediaReducer";
import internalReducer from "./reducers/internalReducer";
import externalReducer from "./reducers/externalReducer";
import clinicalrecordReducer from "./reducers/clinicalrecordReducer";
import messageReducer from "./reducers/messageReducer";
import analyticsReducer from "./reducers/analyticsReducer";
import clientReducer from "./reducers/clientReducer";
import storeReducer from "./reducers/storeReducer";
import supplierReducer from "./reducers/supplierReducer";
import contactLensReducer from "./reducers/contactLensReducer";
import helpReducer from "./reducers/helpReducer";
import stockTakeReducer from "./reducers/stockTakeReducer";
import clinicsReducer from "./reducers/clinicsReducer";
import reconciliationReducer from "./reducers/reconciliationReducer";
import pretestReducer from "./reducers/pretestReducer";
import liveQueReducer from "./reducers/liveQueReducer";
import lunchBreakReducer from "./reducers/lunchBreakReducer";
import directDebitReducer from "./reducers/directDebitReducer";

const initialState = {};

const middleware = [thunk];

const reducers = combineReducers({
  clinics: clinicsReducer,
  user: userReducer,
  inventory: inventoryReducer,
  update: updateReducer,
  variant: variantReducer,
  brand: brandReducer,
  category: categoryReducer,
  product: productReducer,
  patient: patientReducer,
  appointment: appointmentReducer,
  employee: employeeReducer,
  service: serviceReducer,
  prescription: prescriptionReducer,
  account: accountReducer,
  currency: currencyReducer,
  transaction: transactionReducer,
  payment: paymentReducer,
  dispensing: dispensingReducer,
  recall: recallReducer,
  todo: todoReducer,
  voucher: voucherReducer,
  tax: taxReducer,
  sale: SaleReducer,
  note: noteReducer,
  media: mediaReducer,
  internal: internalReducer,
  external: externalReducer,
  clinical: clinicalrecordReducer,
  message: messageReducer,
  analytics: analyticsReducer,
  client: clientReducer,
  store: storeReducer,
  supplier: supplierReducer,
  contactLens: contactLensReducer,
  helpMessage: helpReducer,
  stockTake: stockTakeReducer,
  reconciliation: reconciliationReducer,
  pretest: pretestReducer,
  liveques: liveQueReducer,
  lunchBreak: lunchBreakReducer,
  directDebit: directDebitReducer,
});

const persistConfig = { // configuration object for redux-persist
  key: 'root',
  storage, // define which storage to use
}

const persistedReducer = persistReducer(persistConfig, reducers) // create a persisted reducer

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(applyMiddleware(...middleware));

const store = createStore(persistedReducer, initialState, enhancer);

const  persistor = persistStore(store); // used to create the persisted store, persistor will be used in the next step


export {store, persistor}
// export default store;
