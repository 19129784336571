const ROUTES = {
  LOGOUT: "/app/logout",
  TODOS: "/app/todos",
  LIVE_QUE: "/app/live-que",
  CLINICS: "/app/clinics",
  VOUCHER: "/app/voucher",
  ADD_USER: "/app/users/add",
  USERS: "/app/users",
  PRACTICE: "/app/practice",
  STAFFS: "/app/staff",
  SUPPLIER: "/app/supplier",
  ADD_PATIENT: "/app/patients/add",
  PRODUCTS: "/app/products",
  COLLECTIONS: "/app/collections",
  VARIANTS: "/app/variants",
  BRANDS: "/app/brands",
  SALES: "/app/sales",
  STORES: "/app/stores",
  RECALLS: "/app/recalls",
  PRODUCT_IMPORTS: "/app/product/csv/import",
  PRODUCT_CSV: "/app/product/csv",
  DEMO: "/app",
  PATIENTS: "/app/patients",
  APPOINTMENTS: "/app/appointments",
  ACCOUNTS: "/app/cash/accounts",
  INVOICES: "/app/cash/invoices",
  PROFILE: "/app/profile",
  TRANSACTIONS: "/app/cash/transactions",
  DISPENSING: "/app/patient/dispensing",
  LABS: "/app/patient/dispensing?page=labs",
  PENDING: "/app/patient/dispensing?page=pending",
  ADD_DISPENSING: "/app/patient/dispensing/add",
  CLINICALS: "/app/patient/clinical-records",
  ADD_CLINICALS: "/app/patient/clinical-records/add",
  CONTACT_LENSES: "/app/patient/contact-lenses",
  ADD_CONTACT_LENSES: "/app/patient/contact-lenses/add",
  CASH: "/app/cash",
  PAYMENT: "/app/cash/payments",
  TRANSACTIONPAGE: "/app/cash",
  ANALYTICS: "/app/analytics",
  SERVICES: "/app/services",
  CLIENT: "/app/client",
  SETTINGS: "/app/settings",
  ADD_PATIENTS: "/app/patients/add",
  ADD_PROUDUCT: "/app/products/add",
  PATIENTS_DETAILS: "/app/patients/:id",
  PRODUCT_DETAILS: "/app/products/:id",
  VARIANT_DETAILS: "/app/variants/:id",
  ADD_PATIENTS_CSV_UPLOAD: "/app/patients/add/csv/upload",
  ADD_PATIENTS_CSV_INSTTRUCTIONS: "/app/patients/add/csv/instructions",
  DASHBOARD: "/",
  INSIGHTS: "/app/insights",
  HELP: "/app/help",
  TAX: "/app/tax",
  MEDIA: "/app/media",
  EXTRAS: "/app/extras",
  MESSAGES: "/app/messages",
  EMAIL: "/app/messages?page=email",
  //stock
  STOCKS: "/app/stocks",
  RECONCILIATION:"/app/reconciliation",
  STOCK_TAKE:"/app/stock-take",
  LUNCH_BREAK:"/app/lunch-break",

//   Pre Test
  PRE_TEST: "/app/pre-test",
  DIRECT_DEBIT: "/app/direct-debit",
};

const PARAMS = {
  ADMIN: "admin",
  GOOGLE_API_URL: "https://maps.googleapis.com/maps/api",
  GET_ADDRESSES_API_URL: "https://api.getAddress.io/find",
  VALIDATE_POST_CODE_URL: "https://api.postcodes.io/postcodes",
  EGOS_URL: "https://secure.uat.pcse.england.nhs.uk/OPH/ClaimInject/Index",

  REPEAT: [
    { code: "DAILY", label: "DAILY" },
    { code: "WEEKLY", label: "WEEKLY" },
    { code: "MONTHLY", label: "MONTHLY" },
    { code: "YEARLY", label: "YEARLY" },
  ],

  EXTERNAL_EYE_OPTIONS: [
    { code: "Healthy, Clear", label: "Healthy, Clear" },
    // { code: "Clear", label: "Clear" },
  ],
  GENERAL_HEALTH: [
    { value: "No General Health Problems" },
  ],
  MEDICATION: [
    { value: "No Medications"},
  ],
  OCCULAR_HEALTH: [
    { value: "No Ocular Issues - No inf/HES/ops/patching"},
  ],
  FAMILY_HEALTH: [
    { value: "No Family Health/Ocular Issues"},
  ],
  HOURS_OPTIONS: [
    { value: "1-3" },
    { value: "3-6" },
    { value: "6-9" },
    { value: "9-12" },
    { value: "12+" },
  ],
  HOURS_PER_DAY_OPTIONS: [
    { value: "1-3" },
    { value: "3-6" },
    { value: "6-9" },
    { value: "9-12" },
    { value: "12-15" },
    { value: "15+" },
  ],
  MOTILITY: [
    { code: "Full and Smooth" },
    { code: "Abnormal" },
    { code: "Not done" },
  ],
  PUPILS: [
    { code: "Normal DCN, PERRL, No RAPD" },
    { code: "Abnormal" },
    { code: "Not done" },
  ],
  ACCOMODATION: [
    { code: "Normal for age" },
    { code: "Abnormal" },
    { code: "Not done" },
  ],
  NPC: [
    { code: "Normal" },
    { code: "Abnormal" },
    { code: "Not done" },
  ],
  PD_OPTIONS: [
    {
      value: "30mm",
    },
    {
      value: "31mm",
    },
    {
      value: "32mm",
    },
    {
      value: "33mm",
    },
    {
      value: "34mm",
    },
    {
      value: "35mm",
    },
    {
      value: "36mm",
    },
    {
      value: "37mm",
    },
    {
      value: "38mm",
    },
    {
      value: "39mm",
    },
    {
      value: "40mm",
    },
    {
      value: "41mm",
    },
    {
      value: "42mm",
    },
    {
      value: "43mm",
    },
    {
      value: "44mm",
    },
    {
      value: "45mm",
    },
    {
      value: "46mm",
    },
    {
      value: "47mm",
    },
    {
      value: "48mm",
    },
    {
      value: "49mm",
    },
    {
      value: "50mm",
    },
    {
      value: "51mm",
    },
    {
      value: "52mm",
    },
    {
      value: "53mm",
    },
    {
      value: "54mm",
    },
    {
      value: "55mm",
    },
    {
      value: "56mm",
    },
    {
      value: "57mm",
    },
    {
      value: "58mm",
    },
    {
      value: "59mm",
    },
    {
      value: "60mm",
    },
    {
      value: "61mm",
    },
    {
      value: "62mm",
    },
    {
      value: "63mm",
    },
    {
      value: "64mm",
    },
    {
      value: "65mm",
    },
    {
      value: "66mm",
    },
    {
      value: "67mm",
    },
    {
      value: "68mm",
    },
    {
      value: "69mm",
    },
    {
      value: "70mm",
    },
    {
      value: "71mm",
    },
    {
      value: "72mm",
    },
    {
      value: "73mm",
    },
    {
      value: "74mm",
    },
    {
      value: "75mm",
    },
    {
      value: "76mm",
    },
    {
      value: "77mm",
    },
    {
      value: "78mm",
    },
    {
      value: "79mm",
    },
    {
      value: "80mm",
    },
    {
      value: "81mm",
    },
    {
      value: "82mm",
    },
    {
      value: "83mm",
    },
    {
      value: "84mm",
    },
    {
      value: "85mm",
    },
    {
      value: "86mm",
    },
    {
      value: "87mm",
    },
    {
      value: "88mm",
    },
    {
      value: "89mm",
    },
    {
      value: "90mm",
    },
    {
      value: "91mm",
    },
    {
      value: "92mm",
    },
    {
      value: "93mm",
    },
    {
      value: "94mm",
    },
    {
      value: "95mm",
    },
    {
      value: "96mm",
    },
    {
      value: "97mm",
    },
    {
      value: "98mm",
    },
    {
      value: "99mm",
    },
    {
      value: "100mm",
    },
  ],
  MEDICATION_OPTIONS: [
    { code: "Nil", label: "Nil" },
    { code: "Atenolol", label: "Atenolol" },
    { code: "Ramapril", label: "Ramapril" },
    { code: "Lisinopril", label: "Lisinopril" },
    { code: "Amolodipine", label: "Amolodipine" },
    { code: "Bendroflorozide", label: "Bendroflorozide" },
    { code: "Statin", label: "Statin" },
    { code: "Metfomin", label: "Metfomin" },
    { code: "Insulin", label: "Insulin" },
    { code: "Citalopram", label: "Citalopram" },
    { code: "Gabapentin", label: "Gabapentin" },
    { code: "Codeine", label: "Codeine" },
    { code: "Prednisolone", label: "Prednisolone" },
    { code: "Allopurinol", label: "Allopurinol" },
    { code: "Ventolin", label: "Ventolin" },
    { code: "Letrozol", label: "Letrozol" },
    { code: "Hydroxochloroquinine", label: "Hydroxochloroquinine" },
  ],
  LIDS_OPTIONS: [
    { code: "Healthy, Clear", label: "Healthy, Clear" },
    // { code: "Healthy", label: "Healthy" },
    // { code: "Clear", label: "Clear" },
  ],
  FITTING_OPTIONS: [
    { code: "Good" },
    { code: "Average" },
    { code: "Poor" },
  ],
  ANTERIOR_CHAMBER_OPTIONS: [
    { code: "0.00" },
    { code: "0.05" },
    { code: "0.10" },
    { code: "0.15" },
    { code: "0.20" },
    { code: "0.25" },
    { code: "0.30" },
    { code: "0.35" },
    { code: "0.40" },
    { code: "0.45" },
    { code: "0.50" },
    { code: "0.55" },
    { code: "0.60" },
    { code: "0.65" },
    { code: "0.70" },
    { code: "0.75" },
    { code: "0.80" },
    { code: "0.85" },
    { code: "0.90" },
    { code: "0.95" },
    { code: "1.00" }
  ],
  CONJC_OPTIONS: [
    {
      code: "No Injection, No hyperaemia, Healthy, Clear",
      label: "No Injection, No hyperaemia, Healthy, Clear",
    },
    // { code: "No hyperaemia", label: "No hyperaemia" },
    // { code: "Healthy", label: "Healthy" },
    // { code: "Clear", label: "Clear" },
  ],
  DISC_OPTIONS: [
    {
      code: "Neuro retinal rim healthy, Disc margin healthy",
      label: "Neuro retinal rim healthy, Disc margin healthy",
    },
    // { code: "Disc margin healthy", label: "Disc margin healthy" },
  ],
  CD_OPTIONS: [
    { code: "0.0", label: "0.0" },
    { code: "0.5", label: "0.5" },
    { code: "0.1", label: "0.1" },
    { code: "0.5", label: "0.5" },
    { code: "0.2", label: "0.2" },
    { code: "0.25", label: "0.25" },
    { code: "0.3", label: "0.3" },
    { code: "0.35", label: "0.35" },
    { code: "0.4", label: "0.4" },
    { code: "0.45", label: "0.45" },
    { code: "0.5", label: "0.5" },
    { code: "0.55", label: "0.55" },
    { code: "0.6", label: "0.6" },
    { code: "0.65", label: "0.65" },
    { code: "0.7", label: "0.7" },
    { code: "0.75", label: "0.75" },
    { code: "0.8", label: "0.8" },
    { code: "0.85", label: "0.85" },
    { code: "0.9", label: "0.9" },
    { code: "0.95", label: "0.95" },
    { code: "1.0", label: "1.0" },
  ],
  AV_OPTIONS: [
    { code: "2/3", label: "2/3" },
    { code: "1/2", label: "1/2" },
    { code: "1/3", label: "1/3" },
    { code: "1/4", label: "1/4" },
    { code: "Regular Calibre", label: "Regular Calibre" },
    { code: "No tortuosity", label: "No tortuosity" },
    { code: "No nipping", label: "No nipping" },
    { code: "Healthy", label: "Healthy" },
  ],
  VESSELS_OPTIONS: [
    {
      code: "No nipping, Healthy, Regular Calibre",
      label: "No nipping, Healthy, Regular Calibre",
    },
    // { code: "Healthy", label: "Healthy" },
    // { code: "Regular Calibre", label: "Regular Calibre" },
  ],
  CORNEA_OPTIONS: [
    { code: "Healthy, Clear, No scars", label: "Healthy, Clear, No scars" },
    // { code: "Clear", label: "Clear" },
    // { code: "No scars", label: "No scars" },
  ],
  VITROUS_OPTIONS: [{ code: "Clear", label: "Clear" }],

  MACULA_OPTIONS: [
    { code: "Healthy, Fovea Healthy", label: "Healthy, Fovea Healthy" },
    // { code: "Fovea Healthy", label: "Fovea Healthy" },
  ],
  FUNDUS_OPTIONS: [
    {
      code: "Healthy, Pink, No lesions seen",
      label: "Healthy, Pink, No lesions seen",
    },
    // { code: "Pink", label: "Pink" },
    // { code: "No lesions seen", label: "No lesions seen" },
  ],
  LENS_OPTIONS: [
    {
      code: "Healthy, Clear, No lesions seen, No Opacities",
      label: "Healthy, Clear, No lesions seen, No Opacities",
    },
    // { code: "Clear", label: "Clear" },
    // { code: "No lesions seen", label: "No lesions seen" },
  ],
  LENS_TYPES: [
    { code: "Daily Disposable", label: "Daily Disposable" },
    { code: "Monthly", label: "Monthly" },
    { code: "Yearly", label: "Yearly" },
    { code: "Coloured lenses", label: "Coloured lenses" },
  ],
  TRANSACTION_TYPES: [
    { code: "INCOME", label: "INCOME" },
    { code: "EXPENSE", label: "EXPENSE" },
  ],
  REPEATYPES: {
    DAILY: "Day(s)",
    WEEKLY: "Week(s)",
    MONTHLY: "Month(s)",
    YEARLY: "Year(s)",
  },
  ETHNICITY: [
    { code: "White British", label: "White British" },
    { code: "White Irish", label: "White Irish" },
    { code: "Any other White background", label: "Any other White background" },
    { code: "White and Black Caribbean", label: "White and Black Caribbean" },
    { code: "White and Black African", label: "White and Black African" },
    { code: "White and Asian", label: "White and  Asian" },
    { code: "Any other mixed background", label: "Any other mixed background" },
    {
      code: "Asian or Asian British Indian",
      label: "Asian or Asian British Indian",
    },
    {
      code: "Asian or Asian British Pakistani",
      label: "Asian or Asian British Pakistani",
    },
    {
      code: "Asian or Asian British Bangladeshi",
      label: "Asian or Asian British Bangladeshi",
    },
    {
      code: "Black or Black British Caribbean",
      label: "Black or Black British Caribbean",
    },
  ],
  PAYMENTS_STATUSES: [
    { value: 1, label: "Paid" },
    { value: 2, label: "Not Paid" },
  ],
  STOCK_STATUSES: [
    { value: 2, label: "In Stock" },
    { value: 1, label: "Low On Stock" },
    { value: 0, label: "Out of Stock" },
  ],
  TANOMETRY_EQUIPMENTS: [
    { code: "Goldmann" },
    { code: "Pulsair" },
    { code: "Icare" },
    { code: "Other" },
  ],
  TITLES: [
    {
      value: "Ms.",
      label: "Ms.",
    },
    {
      value: "Mrs.",
      label: "Mrs.",
    },
    {
      value: "Miss.",
      label: "Miss.",
    },
    {
      value: "Mr.",
      label: "Mr.",
    },
    {
      value: "Dr",
      label: "Dr. (Doctor)",
    },
    {
      value: "Esq.",
      label: "Esq. (Esquire)",
    },
    {
      value: "Hon.",
      label: "Hon. (Honorable)",
    },
    {
      value: "Rt. Hon.",
      label: "Rt. Hon. (Right Honorable)",
    },
    {
      value: "Messrs.",
      label: "Messrs. (plural of Mr.)",
    },
    {
      value: "Mmes.",
      label: "Mmes. (plural of Mrs.)",
    },
    {
      value: "Msgr.",
      label: "Msgr. (Monsignor)",
    },
    {
      value: "Prof.",
      label: "Prof. (Professor)",
    },
    {
      value: "Rev.",
      label: "Rev. (Reverend)",
    },
    {
      value: "Sr.",
      label: "Sr. (Senior)",
    },
    {
      value: "Jr.",
      label: "Jr. (Junior)",
    },
    {
      value: "St.",
      label: "St. (Saint)",
    },
  ],
  AXIS: [
    {
      value: 0,
      label: "0",
    },
    {
      value: 1,
      label: "1",
    },
    {
      value: 2,
      label: "2",
    },
    {
      value: 3,
      label: "3",
    },
    {
      value: 4,
      label: "4",
    },
    {
      value: 5,
      label: "5",
    },
    {
      value: 6,
      label: "6",
    },
    {
      value: 7,
      label: "7",
    },
    {
      value: 8,
      label: "8",
    },
    {
      value: 9,
      label: "9",
    },
    {
      value: 10,
      label: "10",
    },
    {
      value: 11,
      label: "11",
    },
    {
      value: 12,
      label: "12",
    },
    {
      value: 13,
      label: "13",
    },
    {
      value: 14,
      label: "14",
    },
    {
      value: 15,
      label: "15",
    },
    {
      value: 16,
      label: "16",
    },
    {
      value: 17,
      label: "17",
    },
    {
      value: 18,
      label: "18",
    },
    {
      value: 19,
      label: "19",
    },
    {
      value: 20,
      label: "20",
    },
    {
      value: 21,
      label: "21",
    },
    {
      value: 22,
      label: "22",
    },
    {
      value: 23,
      label: "23",
    },
    {
      value: 24,
      label: "24",
    },
    {
      value: 25,
      label: "25",
    },
    {
      value: 26,
      label: "26",
    },
    {
      value: 27,
      label: "27",
    },
    {
      value: 28,
      label: "28",
    },
    {
      value: 29,
      label: "29",
    },
    {
      value: 30,
      label: "30",
    },
    {
      value: 31,
      label: "31",
    },
    {
      value: 32,
      label: "32",
    },
    {
      value: 33,
      label: "33",
    },
    {
      value: 34,
      label: "34",
    },
    {
      value: 35,
      label: "35",
    },
    {
      value: 36,
      label: "36",
    },
    {
      value: 37,
      label: "37",
    },
    {
      value: 38,
      label: "38",
    },
    {
      value: 39,
      label: "39",
    },
    {
      value: 40,
      label: "40",
    },
    {
      value: 41,
      label: "41",
    },
    {
      value: 42,
      label: "42",
    },
    {
      value: 43,
      label: "43",
    },
    {
      value: 44,
      label: "44",
    },
    {
      value: 45,
      label: "45",
    },
    {
      value: 46,
      label: "46",
    },
    {
      value: 47,
      label: "47",
    },
    {
      value: 48,
      label: "48",
    },
    {
      value: 49,
      label: "49",
    },
    {
      value: 50,
      label: "50",
    },
    {
      value: 51,
      label: "51",
    },
    {
      value: 52,
      label: "52",
    },
    {
      value: 53,
      label: "53",
    },
    {
      value: 54,
      label: "54",
    },
    {
      value: 55,
      label: "55",
    },
    {
      value: 56,
      label: "56",
    },
    {
      value: 57,
      label: "57",
    },
    {
      value: 58,
      label: "58",
    },
    {
      value: 59,
      label: "59",
    },
    {
      value: 60,
      label: "60",
    },
    {
      value: 61,
      label: "61",
    },
    {
      value: 62,
      label: "62",
    },
    {
      value: 63,
      label: "63",
    },
    {
      value: 64,
      label: "64",
    },
    {
      value: 65,
      label: "65",
    },
    {
      value: 66,
      label: "66",
    },
    {
      value: 67,
      label: "67",
    },
    {
      value: 68,
      label: "68",
    },
    {
      value: 69,
      label: "69",
    },
    {
      value: 70,
      label: "70",
    },
    {
      value: 71,
      label: "71",
    },
    {
      value: 72,
      label: "72",
    },
    {
      value: 73,
      label: "73",
    },
    {
      value: 74,
      label: "74",
    },
    {
      value: 75,
      label: "75",
    },
    {
      value: 76,
      label: "76",
    },
    {
      value: 77,
      label: "77",
    },
    {
      value: 78,
      label: "78",
    },
    {
      value: 79,
      label: "79",
    },
    {
      value: 80,
      label: "80",
    },
    {
      value: 81,
      label: "81",
    },
    {
      value: 82,
      label: "82",
    },
    {
      value: 83,
      label: "83",
    },
    {
      value: 84,
      label: "84",
    },
    {
      value: 85,
      label: "85",
    },
    {
      value: 86,
      label: "86",
    },
    {
      value: 87,
      label: "87",
    },
    {
      value: 88,
      label: "88",
    },
    {
      value: 89,
      label: "89",
    },
    {
      value: 90,
      label: "90",
    },
    {
      value: 91,
      label: "91",
    },
    {
      value: 92,
      label: "92",
    },
    {
      value: 93,
      label: "93",
    },
    {
      value: 94,
      label: "94",
    },
    {
      value: 95,
      label: "95",
    },
    {
      value: 96,
      label: "96",
    },
    {
      value: 97,
      label: "97",
    },
    {
      value: 98,
      label: "98",
    },
    {
      value: 99,
      label: "99",
    },
    {
      value: 100,
      label: "100",
    },
    {
      value: 101,
      label: "101",
    },
    {
      value: 102,
      label: "102",
    },
    {
      value: 103,
      label: "103",
    },
    {
      value: 104,
      label: "104",
    },
    {
      value: 105,
      label: "105",
    },
    {
      value: 106,
      label: "106",
    },
    {
      value: 107,
      label: "107",
    },
    {
      value: 108,
      label: "108",
    },
    {
      value: 109,
      label: "109",
    },
    {
      value: 110,
      label: "110",
    },
    {
      value: 111,
      label: "111",
    },
    {
      value: 112,
      label: "112",
    },
    {
      value: 113,
      label: "113",
    },
    {
      value: 114,
      label: "114",
    },
    {
      value: 115,
      label: "115",
    },
    {
      value: 116,
      label: "116",
    },
    {
      value: 117,
      label: "117",
    },
    {
      value: 118,
      label: "118",
    },
    {
      value: 119,
      label: "119",
    },
    {
      value: 120,
      label: "120",
    },
    {
      value: 121,
      label: "121",
    },
    {
      value: 122,
      label: "122",
    },
    {
      value: 123,
      label: "123",
    },
    {
      value: 124,
      label: "124",
    },
    {
      value: 125,
      label: "125",
    },
    {
      value: 126,
      label: "126",
    },
    {
      value: 127,
      label: "127",
    },
    {
      value: 128,
      label: "128",
    },
    {
      value: 129,
      label: "129",
    },
    {
      value: 130,
      label: "130",
    },
    {
      value: 131,
      label: "131",
    },
    {
      value: 132,
      label: "132",
    },
    {
      value: 133,
      label: "133",
    },
    {
      value: 134,
      label: "134",
    },
    {
      value: 135,
      label: "135",
    },
    {
      value: 136,
      label: "136",
    },
    {
      value: 137,
      label: "137",
    },
    {
      value: 138,
      label: "138",
    },
    {
      value: 139,
      label: "139",
    },
    {
      value: 140,
      label: "140",
    },
    {
      value: 141,
      label: "141",
    },
    {
      value: 142,
      label: "142",
    },
    {
      value: 143,
      label: "143",
    },
    {
      value: 144,
      label: "144",
    },
    {
      value: 145,
      label: "145",
    },
    {
      value: 146,
      label: "146",
    },
    {
      value: 147,
      label: "147",
    },
    {
      value: 148,
      label: "148",
    },
    {
      value: 149,
      label: "149",
    },
    {
      value: 150,
      label: "150",
    },
    {
      value: 151,
      label: "151",
    },
    {
      value: 152,
      label: "152",
    },
    {
      value: 153,
      label: "153",
    },
    {
      value: 154,
      label: "154",
    },
    {
      value: 155,
      label: "155",
    },
    {
      value: 156,
      label: "156",
    },
    {
      value: 157,
      label: "157",
    },
    {
      value: 158,
      label: "158",
    },
    {
      value: 159,
      label: "159",
    },
    {
      value: 160,
      label: "160",
    },
    {
      value: 161,
      label: "161",
    },
    {
      value: 162,
      label: "162",
    },
    {
      value: 163,
      label: "163",
    },
    {
      value: 164,
      label: "164",
    },
    {
      value: 165,
      label: "165",
    },
    {
      value: 166,
      label: "166",
    },
    {
      value: 167,
      label: "167",
    },
    {
      value: 168,
      label: "168",
    },
    {
      value: 169,
      label: "169",
    },
    {
      value: 170,
      label: "170",
    },
    {
      value: 171,
      label: "171",
    },
    {
      value: 172,
      label: "172",
    },
    {
      value: 173,
      label: "173",
    },
    {
      value: 174,
      label: "174",
    },
    {
      value: 175,
      label: "175",
    },
    {
      value: 176,
      label: "176",
    },
    {
      value: 177,
      label: "177",
    },
    {
      value: 178,
      label: "178",
    },
    {
      value: 179,
      label: "179",
    },
    {
      value: 180,
      label: "180",
    },
  ],
  PRESSURE: [
    {
      value: 0,
      label: "0",
    },
    {
      value: 1,
      label: "1",
    },
    {
      value: 2,
      label: "2",
    },
    {
      value: 3,
      label: "3",
    },
    {
      value: 4,
      label: "4",
    },
    {
      value: 5,
      label: "5",
    },
    {
      value: 6,
      label: "6",
    },
    {
      value: 7,
      label: "7",
    },
    {
      value: 8,
      label: "8",
    },
    {
      value: 9,
      label: "9",
    },
    {
      value: 10,
      label: "10",
    },
    {
      value: 11,
      label: "11",
    },
    {
      value: 12,
      label: "12",
    },
    {
      value: 13,
      label: "13",
    },
    {
      value: 14,
      label: "14",
    },
    {
      value: 15,
      label: "15",
    },
    {
      value: 16,
      label: "16",
    },
    {
      value: 17,
      label: "17",
    },
    {
      value: 18,
      label: "18",
    },
    {
      value: 19,
      label: "19",
    },
    {
      value: 20,
      label: "20",
    },
    {
      value: 21,
      label: "21",
    },
    {
      value: 22,
      label: "22",
    },
    {
      value: 23,
      label: "23",
    },
    {
      value: 24,
      label: "24",
    },
    {
      value: 25,
      label: "25",
    },
    {
      value: 26,
      label: "26",
    },
    {
      value: 27,
      label: "27",
    },
    {
      value: 28,
      label: "28",
    },
    {
      value: 29,
      label: "29",
    },
    {
      value: 30,
      label: "30",
    },
    {
      value: 31,
      label: "31",
    },
    {
      value: 32,
      label: "32",
    },
    {
      value: 33,
      label: "33",
    },
    {
      value: 34,
      label: "34",
    },
    {
      value: 35,
      label: "35",
    },
    {
      value: 36,
      label: "36",
    },
    {
      value: 37,
      label: "37",
    },
    {
      value: 38,
      label: "38",
    },
    {
      value: 39,
      label: "39",
    },
    {
      value: 40,
      label: "40",
    },
    {
      value: 41,
      label: "41",
    },
    {
      value: 42,
      label: "42",
    },
    {
      value: 43,
      label: "43",
    },
    {
      value: 44,
      label: "44",
    },
    {
      value: 45,
      label: "45",
    },
    {
      value: 46,
      label: "46",
    },
    {
      value: 47,
      label: "47",
    },
    {
      value: 48,
      label: "48",
    },
    {
      value: 49,
      label: "49",
    },
    {
      value: 50,
      label: "50",
    },
    {
      value: 51,
      label: "51",
    },
    {
      value: 52,
      label: "52",
    },
    {
      value: 53,
      label: "53",
    },
    {
      value: 54,
      label: "54",
    },
    {
      value: 55,
      label: "55",
    },
    {
      value: 56,
      label: "56",
    },
    {
      value: 57,
      label: "57",
    },
    {
      value: 58,
      label: "58",
    },
    {
      value: 59,
      label: "59",
    },
    {
      value: 60,
      label: "60",
    },
    {
      value: 61,
      label: "61",
    },
    {
      value: 62,
      label: "62",
    },
    {
      value: 63,
      label: "63",
    },
    {
      value: 64,
      label: "64",
    },
    {
      value: 65,
      label: "65",
    },
    {
      value: 66,
      label: "66",
    },
    {
      value: 67,
      label: "67",
    },
    {
      value: 68,
      label: "68",
    },
    {
      value: 69,
      label: "69",
    },
    {
      value: 70,
      label: "70",
    },
    {
      value: 71,
      label: "71",
    },
    {
      value: 72,
      label: "72",
    },
    {
      value: 73,
      label: "73",
    },
    {
      value: 74,
      label: "74",
    },
    {
      value: 75,
      label: "75",
    },
    {
      value: 76,
      label: "76",
    },
    {
      value: 77,
      label: "77",
    },
    {
      value: 78,
      label: "78",
    },
    {
      value: 79,
      label: "79",
    },
    {
      value: 80,
      label: "80",
    },
    {
      value: 81,
      label: "81",
    },
    {
      value: 82,
      label: "82",
    },
    {
      value: 83,
      label: "83",
    },
    {
      value: 84,
      label: "84",
    },
    {
      value: 85,
      label: "85",
    },
    {
      value: 86,
      label: "86",
    },
    {
      value: 87,
      label: "87",
    },
    {
      value: 88,
      label: "88",
    },
    {
      value: 89,
      label: "89",
    },
    {
      value: 90,
      label: "90",
    },
    {
      value: 91,
      label: "91",
    },
    {
      value: 92,
      label: "92",
    },
    {
      value: 93,
      label: "93",
    },
    {
      value: 94,
      label: "94",
    },
    {
      value: 95,
      label: "95",
    },
    {
      value: 96,
      label: "96",
    },
    {
      value: 97,
      label: "97",
    },
    {
      value: 98,
      label: "98",
    },
    {
      value: 99,
      label: "99",
    },
    {
      value: 100,
      label: "100",
    },
  ],
  VA_OPTIONS: [
    {
      value: "6/3",
    },
    {
      value: "6/4",
    },
    {
      value: "6/5",
    },
    {
      value: "6/6",
    },
    {
      value: "6/7.5",
    },
    {
      value: "6/9",
    },
    {
      value: "6/10",
    },
    {
      value: "6/12",
    },
    {
      value: "6/15",
    },
    {
      value: "6/20",
    },
    {
      value: "6/24",
    },
    {
      value: "6/36",
    },
    {
      value: "6/60",
    },
    {
      value: "6/60",
    },
    {
      value: "3/60",
    },
    {
      value: "2/60",
    },
    {
      value: "1/60",
    },
    {
      value: "CF",
    },
    {
      value: "HM",
    },
    {
      value: "PL",
    },
    {
      value: "NO PL",
    },
  ],

  NEW_VA_OPTIONS: [
    { "value": "6/3" },
    { "value": "6/4.5" },
    { "value": "6/6" },
    { "value": "6/7.5" },
    { "value": "6/9" },
    { "value": "6/12" },
    { "value": "6/15" },
    { "value": "6/18" },
    { "value": "6/21" },
    { "value": "6/24" },
    { "value": "6/30" },
    { "value": "6/45" },
    { "value": "6/60" },
    { "value": "6/120" },
    { "value": "3/60" },
    { "value": "1/60" },
    { "value": "CF" },
    { "value": "HM" },
    { "value": "LP" },
    { "value": "No LP" }
],
  BASE_OPTIONS: [
    {
      value: "IN",
    },
    {
      value: "OUT",
    },
    {
      value: "UP",
    },
    {
      value: "DOWN",
    },
  ],
  NEAR_VA_OPTIONS: [
    {
      value: "N3",
    },
    {
      value: "N4",
    },
    {
      value: "N5",
    },
    {
      value: "N6",
    },
    {
      value: "N7",
    },
    {
      value: "N8",
    },
    {
      value: "N9",
    },
    {
      value: "N10",
    },
    {
      value: "N11",
    },
    {
      value: "N12",
    },
    {
      value: "N13",
    },
    {
      value: "N14",
    },
    {
      value: "N15",
    },
    {
      value: "N16",
    },
    {
      value: "N17",
    },
    {
      value: "N18",
    },
    {
      value: "N19",
    },
    {
      value: "N20",
    },
    {
      value: "N21",
    },
    {
      value: "N22",
    },
    {
      value: "N23",
    },
    {
      value: "N24",
    },
    {
      value: "N25",
    },
    {
      value: "N26",
    },
    {
      value: "N27",
    },
    {
      value: "N28",
    },
    {
      value: "N29",
    },
    {
      value: "N30",
    },
    {
      value: "N31",
    },
    {
      value: "N32",
    },
    {
      value: "N33",
    },
    {
      value: "N34",
    },
    {
      value: "N35",
    },
    {
      value: "N36",
    },
    {
      value: "N37",
    },
    {
      value: "N38",
    },
    {
      value: "N39",
    },
    {
      value: "N40",
    },
    {
      value: "N41",
    },
    {
      value: "N42",
    },
    {
      value: "N43",
    },
    {
      value: "N44",
    },
    {
      value: "N45",
    },
    {
      value: "N46",
    },
    {
      value: "N47",
    },
    {
      value: "N48",
    },
  ],
  PRISM_OPTIONS: [
    {
      value: "0.5",
    },
    {
      value: "1",
    },
    {
      value: "1.5",
    },
    {
      value: "2",
    },
    {
      value: "2.5",
    },
    {
      value: "3",
    },
    {
      value: "3.5",
    },
    {
      value: "4",
    },
    {
      value: "4.5",
    },
    {
      value: "5",
    },
    {
      value: "5.5",
    },
    {
      value: "6",
    },
    {
      value: "6.5",
    },
    {
      value: "7",
    },
    {
      value: "7.5",
    },
    {
      value: "8",
    },
    {
      value: "8.5",
    },
    {
      value: "9",
    },
    {
      value: "9.5",
    },
    {
      value: "10",
    },
    {
      value: "10.5",
    },
    {
      value: "11",
    },
    {
      value: "11.5",
    },
    {
      value: "12",
    },
    {
      value: "12.5",
    },
    {
      value: "13",
    },
    {
      value: "13.5",
    },
    {
      value: "14",
    },
    {
      value: "14.5",
    },
    {
      value: "15",
    },
    {
      value: "15.5",
    },
    {
      value: "16",
    },
    {
      value: "16.5",
    },
    {
      value: "17",
    },
    {
      value: "17.5",
    },
    {
      value: "18",
    },
    {
      value: "18.5",
    },
    {
      value: "19",
    },
    {
      value: "19.5",
    },
    {
      value: "20",
    },
    {
      value: "20.5",
    },
    {
      value: "21",
    },
    {
      value: "21.5",
    },
    {
      value: "22",
    },
    {
      value: "22.5",
    },
    {
      value: "23",
    },
    {
      value: "23.5",
    },
    {
      value: "24",
    },
    {
      value: "24.5",
    },
    {
      value: "25",
    },
    {
      value: "25.5",
    },
    {
      value: "26",
    },
    {
      value: "26.5",
    },
    {
      value: "27",
    },
    {
      value: "27.5",
    },
    {
      value: "28",
    },
    {
      value: "28.5",
    },
    {
      value: "29",
    },
    {
      value: "29.5",
    },
    {
      value: "30",
    },
  ],
  COUNTRIES: [
    { code: "AD", label: "Andorra", phone: "376" },
    { code: "AE", label: "United Arab Emirates", phone: "971" },
    { code: "AF", label: "Afghanistan", phone: "93" },
    { code: "AG", label: "Antigua and Barbuda", phone: "1-268" },
    { code: "AI", label: "Anguilla", phone: "1-264" },
    { code: "AL", label: "Albania", phone: "355" },
    { code: "AM", label: "Armenia", phone: "374" },
    { code: "AO", label: "Angola", phone: "244" },
    { code: "AQ", label: "Antarctica", phone: "672" },
    { code: "AR", label: "Argentina", phone: "54" },
    { code: "AS", label: "American Samoa", phone: "1-684" },
    { code: "AT", label: "Austria", phone: "43" },
    { code: "AU", label: "Australia", phone: "61", suggested: true },
    { code: "AW", label: "Aruba", phone: "297" },
    { code: "AX", label: "Alland Islands", phone: "358" },
    { code: "AZ", label: "Azerbaijan", phone: "994" },
    { code: "BA", label: "Bosnia and Herzegovina", phone: "387" },
    { code: "BB", label: "Barbados", phone: "1-246" },
    { code: "BD", label: "Bangladesh", phone: "880" },
    { code: "BE", label: "Belgium", phone: "32" },
    { code: "BF", label: "Burkina Faso", phone: "226" },
    { code: "BG", label: "Bulgaria", phone: "359" },
    { code: "BH", label: "Bahrain", phone: "973" },
    { code: "BI", label: "Burundi", phone: "257" },
    { code: "BJ", label: "Benin", phone: "229" },
    { code: "BL", label: "Saint Barthelemy", phone: "590" },
    { code: "BM", label: "Bermuda", phone: "1-441" },
    { code: "BN", label: "Brunei Darussalam", phone: "673" },
    { code: "BO", label: "Bolivia", phone: "591" },
    { code: "BR", label: "Brazil", phone: "55" },
    { code: "BS", label: "Bahamas", phone: "1-242" },
    { code: "BT", label: "Bhutan", phone: "975" },
    { code: "BV", label: "Bouvet Island", phone: "47" },
    { code: "BW", label: "Botswana", phone: "267" },
    { code: "BY", label: "Belarus", phone: "375" },
    { code: "BZ", label: "Belize", phone: "501" },
    { code: "CA", label: "Canada", phone: "1", suggested: true },
    { code: "CC", label: "Cocos (Keeling) Islands", phone: "61" },
    { code: "CD", label: "Congo, Democratic Republic of the", phone: "243" },
    { code: "CF", label: "Central African Republic", phone: "236" },
    { code: "CG", label: "Congo, Republic of the", phone: "242" },
    { code: "CH", label: "Switzerland", phone: "41" },
    { code: "CI", label: "Cote d'Ivoire", phone: "225" },
    { code: "CK", label: "Cook Islands", phone: "682" },
    { code: "CL", label: "Chile", phone: "56" },
    { code: "CM", label: "Cameroon", phone: "237" },
    { code: "CN", label: "China", phone: "86" },
    { code: "CO", label: "Colombia", phone: "57" },
    { code: "CR", label: "Costa Rica", phone: "506" },
    { code: "CU", label: "Cuba", phone: "53" },
    { code: "CV", label: "Cape Verde", phone: "238" },
    { code: "CW", label: "Curacao", phone: "599" },
    { code: "CX", label: "Christmas Island", phone: "61" },
    { code: "CY", label: "Cyprus", phone: "357" },
    { code: "CZ", label: "Czech Republic", phone: "420" },
    { code: "DE", label: "Germany", phone: "49", suggested: true },
    { code: "DJ", label: "Djibouti", phone: "253" },
    { code: "DK", label: "Denmark", phone: "45" },
    { code: "DM", label: "Dominica", phone: "1-767" },
    { code: "DO", label: "Dominican Republic", phone: "1-809" },
    { code: "DZ", label: "Algeria", phone: "213" },
    { code: "EC", label: "Ecuador", phone: "593" },
    { code: "EE", label: "Estonia", phone: "372" },
    { code: "EG", label: "Egypt", phone: "20" },
    { code: "EH", label: "Western Sahara", phone: "212" },
    { code: "ER", label: "Eritrea", phone: "291" },
    { code: "ES", label: "Spain", phone: "34" },
    { code: "ET", label: "Ethiopia", phone: "251" },
    { code: "FI", label: "Finland", phone: "358" },
    { code: "FJ", label: "Fiji", phone: "679" },
    { code: "FK", label: "Falkland Islands (Malvinas)", phone: "500" },
    { code: "FM", label: "Micronesia, Federated States of", phone: "691" },
    { code: "FO", label: "Faroe Islands", phone: "298" },
    { code: "FR", label: "France", phone: "33", suggested: true },
    { code: "GA", label: "Gabon", phone: "241" },
    { code: "GB", label: "England", phone: "44" },
    { code: "GB", label: "Wales", phone: "44" },
    { code: "GB", label: "Scotland", phone: "44" },
    { code: "GB", label: "Northern Ireland", phone: "44" },
    { code: "GD", label: "Grenada", phone: "1-473" },
    { code: "GE", label: "Georgia", phone: "995" },
    { code: "GF", label: "French Guiana", phone: "594" },
    { code: "GG", label: "Guernsey", phone: "44" },
    { code: "GH", label: "Ghana", phone: "233" },
    { code: "GI", label: "Gibraltar", phone: "350" },
    { code: "GL", label: "Greenland", phone: "299" },
    { code: "GM", label: "Gambia", phone: "220" },
    { code: "GN", label: "Guinea", phone: "224" },
    { code: "GP", label: "Guadeloupe", phone: "590" },
    { code: "GQ", label: "Equatorial Guinea", phone: "240" },
    { code: "GR", label: "Greece", phone: "30" },
    {
      code: "GS",
      label: "South Georgia and the South Sandwich Islands",
      phone: "500",
    },
    { code: "GT", label: "Guatemala", phone: "502" },
    { code: "GU", label: "Guam", phone: "1-671" },
    { code: "GW", label: "Guinea-Bissau", phone: "245" },
    { code: "GY", label: "Guyana", phone: "592" },
    { code: "HK", label: "Hong Kong", phone: "852" },
    { code: "HM", label: "Heard Island and McDonald Islands", phone: "672" },
    { code: "HN", label: "Honduras", phone: "504" },
    { code: "HR", label: "Croatia", phone: "385" },
    { code: "HT", label: "Haiti", phone: "509" },
    { code: "HU", label: "Hungary", phone: "36" },
    { code: "ID", label: "Indonesia", phone: "62" },
    { code: "IE", label: "Ireland", phone: "353" },
    { code: "IL", label: "Israel", phone: "972" },
    { code: "IM", label: "Isle of Man", phone: "44" },
    { code: "IN", label: "India", phone: "91" },
    { code: "IO", label: "British Indian Ocean Territory", phone: "246" },
    { code: "IQ", label: "Iraq", phone: "964" },
    { code: "IR", label: "Iran, Islamic Republic of", phone: "98" },
    { code: "IS", label: "Iceland", phone: "354" },
    { code: "IT", label: "Italy", phone: "39" },
    { code: "JE", label: "Jersey", phone: "44" },
    { code: "JM", label: "Jamaica", phone: "1-876" },
    { code: "JO", label: "Jordan", phone: "962" },
    { code: "JP", label: "Japan", phone: "81", suggested: true },
    { code: "KE", label: "Kenya", phone: "254" },
    { code: "KG", label: "Kyrgyzstan", phone: "996" },
    { code: "KH", label: "Cambodia", phone: "855" },
    { code: "KI", label: "Kiribati", phone: "686" },
    { code: "KM", label: "Comoros", phone: "269" },
    { code: "KN", label: "Saint Kitts and Nevis", phone: "1-869" },
    {
      code: "KP",
      label: "Korea, Democratic People's Republic of",
      phone: "850",
    },
    { code: "KR", label: "Korea, Republic of", phone: "82" },
    { code: "KW", label: "Kuwait", phone: "965" },
    { code: "KY", label: "Cayman Islands", phone: "1-345" },
    { code: "KZ", label: "Kazakhstan", phone: "7" },
    { code: "LA", label: "Lao People's Democratic Republic", phone: "856" },
    { code: "LB", label: "Lebanon", phone: "961" },
    { code: "LC", label: "Saint Lucia", phone: "1-758" },
    { code: "LI", label: "Liechtenstein", phone: "423" },
    { code: "LK", label: "Sri Lanka", phone: "94" },
    { code: "LR", label: "Liberia", phone: "231" },
    { code: "LS", label: "Lesotho", phone: "266" },
    { code: "LT", label: "Lithuania", phone: "370" },
    { code: "LU", label: "Luxembourg", phone: "352" },
    { code: "LV", label: "Latvia", phone: "371" },
    { code: "LY", label: "Libya", phone: "218" },
    { code: "MA", label: "Morocco", phone: "212" },
    { code: "MC", label: "Monaco", phone: "377" },
    { code: "MD", label: "Moldova, Republic of", phone: "373" },
    { code: "ME", label: "Montenegro", phone: "382" },
    { code: "MF", label: "Saint Martin (French part)", phone: "590" },
    { code: "MG", label: "Madagascar", phone: "261" },
    { code: "MH", label: "Marshall Islands", phone: "692" },
    {
      code: "MK",
      label: "Macedonia, the Former Yugoslav Republic of",
      phone: "389",
    },
    { code: "ML", label: "Mali", phone: "223" },
    { code: "MM", label: "Myanmar", phone: "95" },
    { code: "MN", label: "Mongolia", phone: "976" },
    { code: "MO", label: "Macao", phone: "853" },
    { code: "MP", label: "Northern Mariana Islands", phone: "1-670" },
    { code: "MQ", label: "Martinique", phone: "596" },
    { code: "MR", label: "Mauritania", phone: "222" },
    { code: "MS", label: "Montserrat", phone: "1-664" },
    { code: "MT", label: "Malta", phone: "356" },
    { code: "MU", label: "Mauritius", phone: "230" },
    { code: "MV", label: "Maldives", phone: "960" },
    { code: "MW", label: "Malawi", phone: "265" },
    { code: "MX", label: "Mexico", phone: "52" },
    { code: "MY", label: "Malaysia", phone: "60" },
    { code: "MZ", label: "Mozambique", phone: "258" },
    { code: "NA", label: "Namibia", phone: "264" },
    { code: "NC", label: "New Caledonia", phone: "687" },
    { code: "NE", label: "Niger", phone: "227" },
    { code: "NF", label: "Norfolk Island", phone: "672" },
    { code: "NG", label: "Nigeria", phone: "234" },
    { code: "NI", label: "Nicaragua", phone: "505" },
    { code: "NL", label: "Netherlands", phone: "31" },
    { code: "NO", label: "Norway", phone: "47" },
    { code: "NP", label: "Nepal", phone: "977" },
    { code: "NR", label: "Nauru", phone: "674" },
    { code: "NU", label: "Niue", phone: "683" },
    { code: "NZ", label: "New Zealand", phone: "64" },
    { code: "OM", label: "Oman", phone: "968" },
    { code: "PA", label: "Panama", phone: "507" },
    { code: "PE", label: "Peru", phone: "51" },
    { code: "PF", label: "French Polynesia", phone: "689" },
    { code: "PG", label: "Papua New Guinea", phone: "675" },
    { code: "PH", label: "Philippines", phone: "63" },
    { code: "PK", label: "Pakistan", phone: "92" },
    { code: "PL", label: "Poland", phone: "48" },
    { code: "PM", label: "Saint Pierre and Miquelon", phone: "508" },
    { code: "PN", label: "Pitcairn", phone: "870" },
    { code: "PR", label: "Puerto Rico", phone: "1" },
    { code: "PS", label: "Palestine, State of", phone: "970" },
    { code: "PT", label: "Portugal", phone: "351" },
    { code: "PW", label: "Palau", phone: "680" },
    { code: "PY", label: "Paraguay", phone: "595" },
    { code: "QA", label: "Qatar", phone: "974" },
    { code: "RE", label: "Reunion", phone: "262" },
    { code: "RO", label: "Romania", phone: "40" },
    { code: "RS", label: "Serbia", phone: "381" },
    { code: "RU", label: "Russian Federation", phone: "7" },
    { code: "RW", label: "Rwanda", phone: "250" },
    { code: "SA", label: "Saudi Arabia", phone: "966" },
    { code: "SB", label: "Solomon Islands", phone: "677" },
    { code: "SC", label: "Seychelles", phone: "248" },
    { code: "SD", label: "Sudan", phone: "249" },
    { code: "SE", label: "Sweden", phone: "46" },
    { code: "SG", label: "Singapore", phone: "65" },
    { code: "SH", label: "Saint Helena", phone: "290" },
    { code: "SI", label: "Slovenia", phone: "386" },
    { code: "SJ", label: "Svalbard and Jan Mayen", phone: "47" },
    { code: "SK", label: "Slovakia", phone: "421" },
    { code: "SL", label: "Sierra Leone", phone: "232" },
    { code: "SM", label: "San Marino", phone: "378" },
    { code: "SN", label: "Senegal", phone: "221" },
    { code: "SO", label: "Somalia", phone: "252" },
    { code: "SR", label: "Suriname", phone: "597" },
    { code: "SS", label: "South Sudan", phone: "211" },
    { code: "ST", label: "Sao Tome and Principe", phone: "239" },
    { code: "SV", label: "El Salvador", phone: "503" },
    { code: "SX", label: "Sint Maarten (Dutch part)", phone: "1-721" },
    { code: "SY", label: "Syrian Arab Republic", phone: "963" },
    { code: "SZ", label: "Swaziland", phone: "268" },
    { code: "TC", label: "Turks and Caicos Islands", phone: "1-649" },
    { code: "TD", label: "Chad", phone: "235" },
    { code: "TF", label: "French Southern Territories", phone: "262" },
    { code: "TG", label: "Togo", phone: "228" },
    { code: "TH", label: "Thailand", phone: "66" },
    { code: "TJ", label: "Tajikistan", phone: "992" },
    { code: "TK", label: "Tokelau", phone: "690" },
    { code: "TL", label: "Timor-Leste", phone: "670" },
    { code: "TM", label: "Turkmenistan", phone: "993" },
    { code: "TN", label: "Tunisia", phone: "216" },
    { code: "TO", label: "Tonga", phone: "676" },
    { code: "TR", label: "Turkey", phone: "90" },
    { code: "TT", label: "Trinidad and Tobago", phone: "1-868" },
    { code: "TV", label: "Tuvalu", phone: "688" },
    { code: "TW", label: "Taiwan, Province of China", phone: "886" },
    { code: "TZ", label: "United Republic of Tanzania", phone: "255" },
    { code: "UA", label: "Ukraine", phone: "380" },
    { code: "UG", label: "Uganda", phone: "256" },
    { code: "US", label: "United States", phone: "1", suggested: true },
    { code: "UY", label: "Uruguay", phone: "598" },
    { code: "UZ", label: "Uzbekistan", phone: "998" },
    { code: "VA", label: "Holy See (Vatican City State)", phone: "379" },
    { code: "VC", label: "Saint Vincent and the Grenadines", phone: "1-784" },
    { code: "VE", label: "Venezuela", phone: "58" },
    { code: "VG", label: "British Virgin Islands", phone: "1-284" },
    { code: "VI", label: "US Virgin Islands", phone: "1-340" },
    { code: "VN", label: "Vietnam", phone: "84" },
    { code: "VU", label: "Vanuatu", phone: "678" },
    { code: "WF", label: "Wallis and Futuna", phone: "681" },
    { code: "WS", label: "Samoa", phone: "685" },
    { code: "XK", label: "Kosovo", phone: "383" },
    { code: "YE", label: "Yemen", phone: "967" },
    { code: "YT", label: "Mayotte", phone: "262" },
    { code: "ZA", label: "South Africa", phone: "27" },
    { code: "ZM", label: "Zambia", phone: "260" },
    { code: "ZW", label: "Zimbabwe", phone: "263" },
  ],
};

const KEYS = {
  API_KEY: "AIzaSyBhAssgNZyl4YImbJ1cslK2AzGe2zHvxlQ",
  APP_STORAGE_KEY: "@COMPAX",
  AUTH_USER: "AUTH_USER",
  AUTH_TOKEN: "AUTH_TOKEN",
  FIRST_TIME_RUNNING_APP: "FIRST_TIME_RUNNING_APP",
};
const MESSAGES = {
  LOGIN_ERROR_MESSAGE: "Invalid Login credentails!",
  SPECIAL_ERROR_MESSAGE: "kindly check your inputs and try again!",
  NETWORK_ERROR_MESSAGE: "Please check your Internet Connection",
  WELCOME_MESSAGE: "Nigeria Compiler Group",
  SERVER_ERROR: "Could not process the request",
  INTRO_MESSAGE:
    "This application is developed to help the monitoring of active logistic services and closing down some of the loop holes that cost large business alot of loss.",
};

const TIME_ZONES = [
  {
    offset: "GMT-12:00",
    name: "Etc/GMT-12",
  },
  {
    offset: "GMT-11:00",
    name: "Etc/GMT-11",
  },
  {
    offset: "GMT-11:00",
    name: "Pacific/Midway",
  },
  {
    offset: "GMT-10:00",
    name: "America/Adak",
  },
  {
    offset: "GMT-09:00",
    name: "America/Anchorage",
  },
  {
    offset: "GMT-09:00",
    name: "Pacific/Gambier",
  },
  {
    offset: "GMT-08:00",
    name: "America/Dawson_Creek",
  },
  {
    offset: "GMT-08:00",
    name: "America/Ensenada",
  },
  {
    offset: "GMT-08:00",
    name: "America/Los_Angeles",
  },
  {
    offset: "GMT-07:00",
    name: "America/Chihuahua",
  },
  {
    offset: "GMT-07:00",
    name: "America/Denver",
  },
  {
    offset: "GMT-06:00",
    name: "America/Belize",
  },
  {
    offset: "GMT-06:00",
    name: "America/Cancun",
  },
  {
    offset: "GMT-06:00",
    name: "America/Chicago",
  },
  {
    offset: "GMT-06:00",
    name: "Chile/EasterIsland",
  },
  {
    offset: "GMT-05:00",
    name: "America/Bogota",
  },
  {
    offset: "GMT-05:00",
    name: "America/Havana",
  },
  {
    offset: "GMT-05:00",
    name: "America/New_York",
  },
  {
    offset: "GMT-04:30",
    name: "America/Caracas",
  },
  {
    offset: "GMT-04:00",
    name: "America/Campo_Grande",
  },
  {
    offset: "GMT-04:00",
    name: "America/Glace_Bay",
  },
  {
    offset: "GMT-04:00",
    name: "America/Goose_Bay",
  },
  {
    offset: "GMT-04:00",
    name: "America/Santiago",
  },
  {
    offset: "GMT-04:00",
    name: "America/La_Paz",
  },
  {
    offset: "GMT-03:00",
    name: "America/Argentina/Buenos_Aires",
  },
  {
    offset: "GMT-03:00",
    name: "America/Montevideo",
  },
  {
    offset: "GMT-03:00",
    name: "America/Araguaina",
  },
  {
    offset: "GMT-03:00",
    name: "America/Godthab",
  },
  {
    offset: "GMT-03:00",
    name: "America/Miquelon",
  },
  {
    offset: "GMT-03:00",
    name: "America/Sao_Paulo",
  },
  {
    offset: "GMT-03:30",
    name: "America/St_Johns",
  },
  {
    offset: "GMT-02:00",
    name: "America/Noronha",
  },
  {
    offset: "GMT-01:00",
    name: "Atlantic/Cape_Verde",
  },
  {
    offset: "GMT",
    name: "Europe/Belfast",
  },
  {
    offset: "GMT",
    name: "Africa/Abidjan",
  },
  {
    offset: "GMT",
    name: "Europe/Dublin",
  },
  {
    offset: "GMT",
    name: "Europe/Lisbon",
  },
  {
    offset: "GMT",
    name: "Europe/London",
  },
  {
    offset: "UTC",
    name: "UTC",
  },
  {
    offset: "GMT+01:00",
    name: "Africa/Algiers",
  },
  {
    offset: "GMT+01:00",
    name: "Africa/Windhoek",
  },
  {
    offset: "GMT+01:00",
    name: "Atlantic/Azores",
  },
  {
    offset: "GMT+01:00",
    name: "Atlantic/Stanley",
  },
  {
    offset: "GMT+01:00",
    name: "Europe/Amsterdam",
  },
  {
    offset: "GMT+01:00",
    name: "Europe/Belgrade",
  },
  {
    offset: "GMT+01:00",
    name: "Europe/Brussels",
  },
  {
    offset: "GMT+02:00",
    name: "Africa/Cairo",
  },
  {
    offset: "GMT+02:00",
    name: "Africa/Blantyre",
  },
  {
    offset: "GMT+02:00",
    name: "Asia/Beirut",
  },
  {
    offset: "GMT+02:00",
    name: "Asia/Damascus",
  },
  {
    offset: "GMT+02:00",
    name: "Asia/Gaza",
  },
  {
    offset: "GMT+02:00",
    name: "Asia/Jerusalem",
  },
  {
    offset: "GMT+03:00",
    name: "Africa/Addis_Ababa",
  },
  {
    offset: "GMT+03:00",
    name: "Asia/Riyadh89",
  },
  {
    offset: "GMT+03:00",
    name: "Europe/Minsk",
  },
  {
    offset: "GMT+03:30",
    name: "Asia/Tehran",
  },
  {
    offset: "GMT+04:00",
    name: "Asia/Dubai",
  },
  {
    offset: "GMT+04:00",
    name: "Asia/Yerevan",
  },
  {
    offset: "GMT+04:00",
    name: "Europe/Moscow",
  },
  {
    offset: "GMT+04:30",
    name: "Asia/Kabul",
  },
  {
    offset: "GMT+05:00",
    name: "Asia/Tashkent",
  },
  {
    offset: "GMT+05:30",
    name: "Asia/Kolkata",
  },
  {
    offset: "GMT+05:45",
    name: "Asia/Katmandu",
  },
  {
    offset: "GMT+06:00",
    name: "Asia/Dhaka",
  },
  {
    offset: "GMT+06:00",
    name: "Asia/Yekaterinburg",
  },
  {
    offset: "GMT+06:30",
    name: "Asia/Rangoon",
  },
  {
    offset: "GMT+07:00",
    name: "Asia/Bangkok",
  },
  {
    offset: "GMT+07:00",
    name: "Asia/Novosibirsk",
  },
  {
    offset: "GMT+08:00",
    name: "Etc/GMT+8",
  },
  {
    offset: "GMT+08:00",
    name: "Asia/Hong_Kong",
  },
  {
    offset: "GMT+08:00",
    name: "Asia/Krasnoyarsk",
  },
  {
    offset: "GMT+08:00",
    name: "Australia/Perth",
  },
  {
    offset: "GMT+08:45",
    name: "Australia/Eucla",
  },
  {
    offset: "GMT+09:00",
    name: "Asia/Irkutsk",
  },
  {
    offset: "GMT+09:00",
    name: "Asia/Seoul",
  },
  {
    offset: "GMT+09:00",
    name: "Asia/Tokyo",
  },
  {
    offset: "GMT+09:30",
    name: "Australia/Adelaide",
  },
  {
    offset: "GMT+09:30",
    name: "Australia/Darwin",
  },
  {
    offset: "GMT+09:30",
    name: "Pacific/Marquesas",
  },
  {
    offset: "GMT+10:00",
    name: "Etc/GMT+10",
  },
  {
    offset: "GMT+10:00",
    name: "Australia/Brisbane",
  },
  {
    offset: "GMT+10:00",
    name: "Australia/Hobart",
  },
  {
    offset: "GMT+10:00",
    name: "Asia/Yakutsk",
  },
  {
    offset: "GMT+10:30",
    name: "Australia/Lord_Howe",
  },
  {
    offset: "GMT+11:00",
    name: "Asia/Vladivostok",
  },
  {
    offset: "GMT+11:30",
    name: "Pacific/Norfolk",
  },
  {
    offset: "GMT+12:00",
    name: "Etc/GMT+12",
  },
  {
    offset: "GMT+12:00",
    name: "Asia/Anadyr",
  },
  {
    offset: "GMT+12:00",
    name: "Asia/Magadan",
  },
  {
    offset: "GMT+12:00",
    name: "Pacific/Auckland",
  },
  {
    offset: "GMT+12:45",
    name: "Pacific/Chatham",
  },
  {
    offset: "GMT+13:00",
    name: "Pacific/Tongatapu",
  },
  {
    offset: "GMT+14:00",
    name: "Pacific/Kiritimati",
  },
];

const ROLES = {
  M: "Level 1",
  A: "Level 2",
};

const DISPENSING_STATUS = {
  dispensed: "Dispensed",
  inHouse: "In House",
  ordered: "Ordered",
  ready: "Ready",
  collected: "Collected"
};

const DISPENSING_APPROVAL_STATUS = [
  {id: "0", name:"Pending"},
  {id: "1", name:"Declined"},
  {id: "2", name:"Approved"},
  {id: "3", name:"Not Applicable"},
]

const DESIGNATION = {
  optometrist: "Optometrist",
  labTech: "Lab tech",
  admin: "Admin",
  opticalAssistant: "Optical assistant",
  opticalStylist: "Optical stylist",
  manager: "Manager"
};

const DASHBOARDLINKS = {
  newPatient: "newPatient",
  todayAppointments: "todayAppointments",
  appointments: "appointments",
  clinicalRecords: "clinicalRecords",
  dispensing: "dispensing",
  collections: "collections",
  till: "till",
  stockcontrol: "stockcontrol",
  labs: "labs",
  patientbook: "patientbook",
  analytics: "analytics",
  appsettings: "appsettings",
  pendingDispensing: "pendingDispensing",
}

const LUNCH_SCHEDULE = [
  {
    hour: 11,
    minute: 0,
    slot: 1
  },
  {
    hour: 11,
    minute: 40,
    slot: 2
  },
  {
    hour: 12,
    minute: 20,
    slot: 3
  },
  {
    hour: 12,
    minute: 20,
    slot: 4
  },
  {
    hour: 13,
    minute: 0,
    slot: 5
  },
  {
    hour: 13,
    minute: 0,
    slot: 6
  },
  {
    hour: 13,
    minute: 40,
    slot: 7
  },
  {
    hour: 13,
    minute: 40,
    slot: 8
  },
  {
    hour: 14,
    minute: 20,
    slot: 10
  },
  {
    hour: 15,
    minute: 0,
    slot: 11
  },
];

const ID = [
    39,35,1
];

export default {
  ROUTES,
  PARAMS,
  KEYS,
  MESSAGES,
  ROLES,
  TIME_ZONES,
  DISPENSING_STATUS,
  DESIGNATION,
  DASHBOARDLINKS,
  LUNCH_SCHEDULE,
  DISPENSING_APPROVAL_STATUS,
  ID
};
