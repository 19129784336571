import React, { Suspense, lazy } from "react";
import { createTheme, responsiveFontSizes } from "@material-ui/core";
import { MuiThemeProvider, CssBaseline } from "@material-ui/core";
import {
  BrowserRouter,
  // Route,
  Switch,
  // Redirect
} from "react-router-dom";
import jwtDecode from "jwt-decode";
import Loader from "./Loader";
import {
  logoutUser,
  getUserData,
  loadAppMode,
} from "./store/actions/userActions";
import { apiInstance } from "./services/api";
import { SET_AUTHENTICATED } from "./store/types";
import { useDarkMode } from "./theming/theme";
import GlobalStyles from "./GlobalStyles";
// import * as serviceWorker from "./serviceWorker";
import Pace from "./shared/components/Pace";
// Redux
import { Provider } from "react-redux";
// import store from "./store/store";
import {store, persistor} from './store/store'
import { PersistGate } from 'redux-persist/integration/react'
// Routes
import ProtectedRoute from "./routes/protected.route";
import AuthRoute from "./routes/auth.route";
// Route Components
const LoggedInComponent = lazy(() => import("./logged_in/components/Main"));

// const LoggedOutComponent = lazy(() => import("./logged_out/components/Main"));

const SignInComponent = lazy(() =>
  import("./logged_out/components/security/SignIn")
);

const ForgotPasswordComponent = lazy(() =>
  import("./logged_out/components/security/ForgotPassword")
);

const ResetPasswordComponent = lazy(() =>
  import("./logged_out/components/security/ResetPassword")
);

const token = localStorage.FBIdToken;

const defaultTheme = localStorage.Theme;

if (token) {
  const decodedToken = jwtDecode(token);
  if (decodedToken.exp * 1000 < Date.now()) {
    store.dispatch(logoutUser());
  } else {
    store.dispatch({ type: SET_AUTHENTICATED });
    apiInstance.defaults.headers.common["Authorization"] = token;
    store.dispatch(getUserData());
  }
}else{
    store.dispatch(logoutUser());
}

if (defaultTheme) {
  store.dispatch(loadAppMode(defaultTheme));
}

function WaitingComponent(Component, toggleDarkMode) {
  return (props) => <Component {...props} toggleDarkMode={toggleDarkMode} />;
}

function App() {
  const [theme, toggleDarkMode] = useDarkMode();

  const themeConfig = responsiveFontSizes(createTheme(theme));

  return (
    <MuiThemeProvider theme={themeConfig}>
      <Provider store={store}>
      <PersistGate loading={<Loader />} persistor={persistor}>
        <BrowserRouter>
          <CssBaseline />
          <GlobalStyles />
          <Pace color={themeConfig.palette.secondary.light} />
          <Suspense fallback={<Loader />}>
            <Switch>
              <ProtectedRoute
                path="/app"
                component={WaitingComponent(LoggedInComponent, toggleDarkMode)}
              />
              <AuthRoute exact path="/login" component={SignInComponent} />
              <AuthRoute
                exact
                path="/forgotpassword"
                component={ForgotPasswordComponent}
              />
              <AuthRoute
                exact
                path="/resetpassword/:code"
                component={ResetPasswordComponent}
              />
              <ProtectedRoute
                path="/"
                component={WaitingComponent(LoggedInComponent, toggleDarkMode)}
              />
              {/* <Route path="/">
                     <Redirect to="/app" />
                 </Route> */}
              {/* <Route path="/" component={LoggedOutComponent}/> */}
            </Switch>
          </Suspense>
        </BrowserRouter>
        </PersistGate>
      </Provider>
    </MuiThemeProvider>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorker.register();

export default App;
