import {
  LOADING_SUPPLIER,
  SET_SUPPLIERS,
  SUPPLIER_ERRORS,
  ADD_SUPPLIER,
  ADD_SUPPLIER_ERRORS,
  LOADING_ADD_SUPPIER,
  LOADING_DELETE_SUPPLIER,
  DELETE_SUPPLIER,
  DELETE_SUPPLIER_ERROR,
  LOADING_UPDATE_SUPPLIER,
  UPDATE_SUPPLIER,
  UPDATE_SUPPLIER_ERROR,
} from "../types";

const initialState = {
  suppliers: [],
  supplier: {},
  loading_suppliers: false,
  loading_delete_supplier: false,
  delete_supplier_errors: null,
  supplier_errors: null,
  links: "",
  meta: 0,
  loading_add_supplier: false,
  add_supplier_errors: null,
  loadingUpdateBrand: false,
  updateBrandErrors: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_SUPPLIER:
      return {
        ...state,
        loading_suppliers: true,
      };

    case SET_SUPPLIERS:
      return {
        ...state,
        suppliers: action.payload.data,
        links: action.payload.next_page_url,
        meta: action.payload.total,
        loading_suppliers: false,
        supplier_errors: null,
      };
    case SUPPLIER_ERRORS:
      return {
        ...state,
        supplier_errors: action.payload,
        loading_suppliers: false,
      };
    case LOADING_ADD_SUPPIER:
      return {
        ...state,
        loading_add_supplier: true,
        add_supplier_errors: null,
      };

    case ADD_SUPPLIER:
      return {
        ...state,
        suppliers: [action.payload, ...state.suppliers],
        loading_add_supplier: false,
        add_supplier_errors: null,
      };
    case ADD_SUPPLIER_ERRORS:
      return {
        ...state,
        loading_add_supplier: false,
        add_supplier_errors: action.payload.errors,
      };

    case LOADING_UPDATE_SUPPLIER:
      return {
        ...state,
        loadingUpdateBrand: true,
        updateBrandErrors: null,
      };

    case UPDATE_SUPPLIER:
      let supplierIndex = state.suppliers.findIndex(
        (x) => x.id === action.payload.id
      );
      const updatedBrands = [...state.suppliers];
      updatedBrands[supplierIndex] = action.payload;
      return {
        ...state,
        suppliers: [...updatedBrands],
        loadingUpdateBrand: false,
        updateBrandErrors: null,
      };

    case UPDATE_SUPPLIER_ERROR:
      return {
        ...state,
        loadingUpdateBrand: false,
        updateBrandErrors: action.payload.errors,
      };
    case LOADING_DELETE_SUPPLIER:
      return {
        ...state,
        loading_delete_supplier: true,
        delete_supplier_errors: null,
      };
    case DELETE_SUPPLIER:
      let del = state.suppliers.findIndex((x) => x.id === action.payload.id);
      return {
        ...state,
        suppliers: state.suppliers.filter((item, index) => index !== del),
        loading_delete_supplier: false,
        delete_supplier_errors: null,
      };
    case DELETE_SUPPLIER_ERROR:
      return {
        ...state,
        loading_add_supplier: false,
        add_supplier_errors: action.payload,
      };
    default:
      return state;
  }
}
