import React from 'react';
import { Route, Redirect } from "react-router-dom";
import { connect } from 'react-redux';

 const ProtectedRoute = ({component: Component, ...rest}) => {
  
  const {
      user:{
          authenticated
        }
      } = rest;
  
    return (
      <Route {...rest} render = {
          (props)=>{
            if (authenticated) {

              return <Component {...props}/>

            }else{

              return <Redirect to={
                {
                  pathname: "/login",
                  state:{
                    from: props.location
                  }
                }
              }

              />
            }
            
          }
      }/>
    )
}


const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(
  mapStateToProps
)(ProtectedRoute);

